.Slide {
    width: auto !important;
    background-color: #ededed;
    padding: 8px 12px;
    border-radius: 20px;
    text-transform: uppercase;
}

.nameAndAmount {
    display: flex;
    flex-direction: column;
}

.amountOfProducts {
    font-size: 10px;
}

.Sort {
    display: flex;
    justify-content: space-around;
    height: 23px;
    padding-bottom: 5px;
}

.Sort h5 {
    margin: 0;
    padding: 0;
}

.container {
    padding: 70px 10px 10px;
}

.sortList {
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-top: 1em;
    padding-bottom: 1.2em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sortListCategories {
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sortListPrice {
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-bottom: 1.2em;
    text-align: left;
}

.sortList:first-child {
    border-top: solid 1px #606060;
}

.filterTitle {
    padding-bottom: 34px;
    font-weight: 600;
    font-size: 16px;
}

.filterChoice {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #606060;
    border-radius: 50%;
    margin-right: 6px;
}

.active {
    border: 4px solid transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--primary-color) 0%, var(--primary-color) 100%) border-box;
}

.sortText {
    display: inline-flex;
    justify-self: center;
    align-self: center;
}

.sortTextPrice {
    display: inline-flex;
    justify-self: center;
    align-self: center;
    padding-bottom: 1.2rem;
}

.line {
    width: 30px;
    height: 2px;
    background-color: #606060;
    margin: 0 15px;
}

.filterContainer {
    max-height: 70vh;
    overflow: scroll;
}

.filterContainer ul {
    padding: 0;
}

.align {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filterForm ul {
    padding: 0;
}

.filterCost {
    min-width: 93px;
    width: 20vw;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    border: solid 1px #ededed;
    color: #606060;
    background: #ededed;
    border-radius: 10px;
    padding: 9px;
}

.filterCost:focus {
    outline: none;
    border: solid 1px #606060;
}

.link {
    width: 13px;
    height: 11px;
}

.applyButton {
    width: 100%;
    background: linear-gradient(
            90.62deg,
            rgba(240, 6, 6, 0.6) 0%,
            rgba(6, 40, 241, 0.6) 49.48%,
            rgba(239, 114, 242, 0.6) 100%
    );
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    margin-top: 88px;
    color: white;
}
