
.likeContainer {
    text-align: left;
    padding: 0 15px;
}

.likeContainer p {
    margin: 11px 0;
}

.likeButton {
    background: #EDEDED;
    border: 2px solid #B5B5B5;
    border-radius: 10px;
    display: inline-flex;
    justify-items: center;
    justify-content: center;
    gap: 5px;
    align-items: center;
    padding: 4px 6px;
    margin-left: 10px;
    cursor: pointer;
}

.likeButton span {
    align-self: center;
    font-size: 12px;
}

.likeButton:active {
    transform: scale(96%);
}
