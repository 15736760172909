.root {
    display: flex;
    flex-direction: column;
    /*height: 90vh;*/
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    padding-bottom: 80px;
    font-family: 'Rubic', sans-serif;
    gap: 10px;
  }

  .header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    background-color: #fff;
    padding: 10px 20px;
  }


  .userInfo {
    margin-bottom: 15px;
    background-color: #fff;
    padding: 10px 20px;
  }
  
  .infoBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .infoTitle {
    font-weight: 700;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
    margin-bottom: 7px;
  }
  
  .infoData {
    font-weight: 700;
    font-size: clamp(7px, 2.8vw, 9px);
    letter-spacing: 0.05em;
    color: #4F8CE7;
  }
  
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* padding-top:10px ; */
    /* margin-bottom: 10px; */
    background: white;
    /* row-gap: 15px; */
    /* padding: 10px 10px 10px; */
    gap : 10px;
}
.buttonCancel{
    width : 25%;
    height: 40px;
    border-radius: 10px;
    border:rgba(231, 79, 79, 1) 1.9px solid;
    color: rgba(231, 79, 79, 1);

    background-color: white;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: clamp(8px, 2.6vw, 10px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    /* margin-bottom: 10px; */
    box-shadow: 0 0 0 1px #f44336;
}

.buttonGetting{
    width: 75%;
    height: 40px;
    border-radius: 10px;
    background: rgba(181, 181, 181, 1) ;
    color: rgba(255, 255, 255, 1);
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 12px); 
    border: none;
    
}

.buttonSupport{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background: #4F8CE7;
    color: rgba(255, 255, 255, 1);
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 12px); 
    border: none;
    
}



.itemRoot{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px;
    margin-bottom: 10px;
}


.itemShop{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Rubik", sans-serif;
    margin-bottom: 10px;
    /* padding: 10px 10px 10px; */
    /* gap : 3px; */

}

.itemShopName{
    font-weight: 700;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;

}
.itemShopReit{  
    font-weight: 400;
    font-size: clamp(8px, 2.7vw, 10px);
    color: rgba(96, 96, 96, 1)

}


.itemImage{
    /* margin-right: 15px;  */
    width: 35%;
    height: 147px;
    gap : 7px;
    display: flex;
    flex-direction: column;
    }


.itemImage img{
        /* width: 147px; */
        height: 147px;
        object-fit: cover;
        border-radius: 5px;
        /* margin-right: 15px; */
    }



.itemContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* margin-bottom: 10px; */
    /* padding: 10px 10px 10px; */
    gap : 10px;
}

.itemInfo{
    height: 147px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start ;
    font-family: "Rubik", sans-serif;
    /* border:rgba(231, 79, 79, 1) 1.9px solid; */
    gap :15px;
}

.priceContainer{
    display: flex;
    flex-direction: column;
    
    align-items: flex-start ;
    padding-left: 5px;
    gap : 5px;

}

.itemName{
    font-weight: 500;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
}

.itemPrice{
    font-weight: 400;
    font-size: clamp(100%, 2.9vw, 12px);
    color: black;
}
.itemTotal{
    font-weight: 700;
    font-size: clamp(100%, 2.9vw, 12px);
}

.itemDescription{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap : 10px;
    font-weight: 400;
    font-size: clamp(8px, 2.7vw, 10px);
    color: rgba(96, 96, 96, 1);
}
.itemLots{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: clamp(6px, 2.5vw, 8px);
    color: rgba(96, 96, 96, 1);
}
.itemStatusContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 140px;
    width: 20%;
    /* margin-bottom: 10px; */
    /* padding: 10px 10px 10px; */
    gap : 10px;
}

.itemStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    border:  1px solid;
    border-radius: 5px;
    width: 60px;
    height: 20px;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 600;
    font-size: clamp(6px, 2.6vw, 8px);
    line-height: 1.2em;
    box-shadow: 0 0 0 1px ;
    color: rgba(96, 96, 96, 1);
}

.itemSteps{
    font-size: clamp(6px, 2.6vw, 8px);
    color: rgba(96, 96, 96, 1);
    font-weight: 400;
    
}

.deliveryContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    
    /* background-color: #fff; */
    /* padding: 10px 20px; */
    margin-top: 10px;
    margin-bottom: 10px;
    gap : 10px;
}

.deliveryInfo{
    display: flex;
    height: 40px;
    width: 35%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(237, 237, 237, 1);
    border-radius: 10px;
    /* padding: 10px 20px; */
    /* margin-bottom: 10px; */
    /* gap: 10px; */
}
.deliveryButton{
    display: flex;
    height: 40px;
    width: 25%;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);

    justify-content: center;
    align-items: center;
    background-color: rgba(181, 181, 181, 1);
    border-radius: 10px;   
}




.totalSection {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    text-align: left;
    gap : 10px;
}

.totalHeader {
    font-weight: 700;
    font-size: clamp(16px, 3.6vw, 18px);
    color: #000;
    text-transform: uppercase;
    text-align: left;
}

.totalCosts {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
}

.totalItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px;  */
}

.totalItemsCount {
    display: flex;
    flex-direction: column;
}

.totalItemsDiff {
    font-weight: 400;
    font-size: clamp(16px, 3.8vw, 20px);
    color: #000;
}

.totalItemsAll {
    font-weight: 400;
    font-size: clamp(10px, 2.8vw, 12px);
    color: #B5B5B5;
}

.totalItemsPrice {
    font-weight: 700;
    font-size: clamp(16px, 3.8vw, 20px);
    color: #000;
}

.totalDivider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 15px 0 20px 0;
    background-color: #000;
}

.finalCost {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finalCostTitle {
    font-weight: 700;
    font-size: clamp(16px, 3.8vw, 20px);

    color: #000;
}

.finalCostSum {
    font-weight: 700;
    font-size: clamp(16px, 3.8vw, 20px);

    color: #000;
}


.totalButtons{
    display: flex;
    flex-direction: column;
    gap : 10px;    

    width: 100%;
    
}

.totalButton, .totalButtonDisable{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: rgba(79, 140, 231, 1);
    border-radius: 10px;
    color: rgba(255, 255, 255, 1);
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(12px, 3.2vw, 16px);
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    border: none;
    /* margin-bottom: 10px; */
}

.totalButtonDisable{
    background: rgba(181, 181, 181, 1) ;

}


.buttonContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap : 10px;
    /* padding: 10px 10px 10px; */
}

.buttonCancel{
    width : 25%;
    height: 40px;
    border-radius: 10px;
    border:rgba(231, 79, 79, 1) 1.9px solid;
    color: rgba(231, 79, 79, 1);

    background-color: white;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: clamp(8px, 2.6vw, 10px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    /* margin-bottom: 10px; */
    box-shadow: 0 0 0 1px #f44336;
}

.buttonGetting, .buttonDecline{
    width : 100%;
    height: 40px;
    border-radius: 10px;
    background: rgba(79, 231, 112, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 12px); 
    border: none;
    
}
.buttonGetting:disabled{
    background: rgba(181, 181, 181, 1) ;
    width: 75%;

}

.buttonDecline{
    background: rgba(231, 79, 79, 1) ;
}


.comment{
    width: 100%;
    height: 60px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(8px, 3.2vw, 12px);
    background-color: rgba(237, 237, 237, 1);
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
    resize: none;
}
