.root {
  background: rgba(96, 96, 96, 0.1);
  font-family: 'Rubik', sans-serif;
  padding-bottom: 80px;
  
}

.pageHeader {
  font-weight: 700;
  font-size: 20px;
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  background-color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.userInfo {
  margin-bottom: 15px;
  background-color: #fff;
  padding: 10px 20px;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.infoTitle {
  font-weight: 700;
  font-size: 13px;
  color: #000;
  margin-bottom: 7px;
}

.infoData {
  font-weight: 700;
  font-size: 9px;
  letter-spacing: 0.05em;
  color: #4F8CE7;
}

/* Bonus section */

.bonusSection {
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #fff;
}

.bonusTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.title {
  font-weight: 700;
  font-size: 13px;
  color: #000;
}

.bonusHelp {
  font-weight: 400;
  font-size: 9px;
  /* color: #FF4848; */
  color: --var(--dark-background-color)
}

.bonusControls {
  display: flex;
  justify-content: space-between;
}

.bonusControlBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.bonusControlName {
  font-weight: 400;
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
}

.bonusControlCount {
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  background: #EDEDED;
  width: 90%;
}

.blue {
  background-color: #4F8CE7;
  color: #fff;
  margin-right: 10px;
}

/* Promo section */

.promoSection {
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: #fff;
}

.promoTitle {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 13px;
  color: #000;
  display: flex;
  justify-content: start;
}

.promoControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enterNumber {
  font-weight: 400;
  font-size: 12px;
  color: #000;
  margin-right: 10px;
}

.enterPromo {
  flex-grow: 2;
  background: #EDEDED;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #606060;
  border: none;
  outline: none;
  padding: 10px 0;
  text-align: center;
  margin-right: 10px;
}

.confirmPromo {
  flex-grow: 1;
  background: #EDEDED;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #606060;
  padding: 10px 5px;
  text-align: center;
}

/* Total section */

.totalSection {
  margin-top: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  text-align: left;
}

.totalSectionHeader {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: left;
}

.totalCosts {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.totalItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.totalItemsCount {
  display: flex;
  flex-direction: column;
}

.totalItemsDiff {
  font-weight: 400;
  font-size: 20px;
  color: #000;
}

.totalItemsAll {
  font-weight: 400;
  font-size: 12px;
  color: #B5B5B5;
}

.totalItemsPrice {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.totalDiscount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.totalDiscountTitle {
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.totalDiscountSum {
  font-weight: 700;
  font-size: 16px;
  color: #E74F4F;
}

.free {
  font-weight: 700;
  font-size: 16px;
  color: #00C62B;
}

.totalDivider {
  display: block;
  height: 1px;
  width: 100%;
  margin: 15px 0 20px 0;
  background-color: #000;
}

.finalCost {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finalCostTitle {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.finalCostSum {
  font-weight: 700;
  font-size: 20px;
  color: #000;
}

.proceedBtn {
  padding: 10px 0;
  width: 98%;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  background: #4F8CE7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

.small {
  font-size: 12px;
}

.redBg {
  background: #E74F4F;
  margin-top: 15px;
}

.link{
  color: #4F8CE7;
  text-decoration: underline;
  font-weight: 700;
  font-size: 10px;
  
  
}

.text{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
  height : 12px;
  font-weight: 400;
  font-size: 10px;
  color: #000;
  margin-top: 10px;
}


  
.localModalContent {
  max-height: 60vh;
  overflow-y: scroll;
}
