.container {
    padding-left: 10px;
    padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
  gap :15px;
  font-family: --var(--main-font);
  overflow-y: auto;
}

.aboutTitle {
    font-weight: 400;
  }

.linksContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin-left: -20px;
}

.link{
    display: flex;
    text-align: left;

    font-size: 12px;
    font-weight: 400;
    color: var(--primary-color);
}

.text{
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  gap : -3px
}
