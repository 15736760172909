.header {
	font-family: 'Rubik', sans-serif;
	/* font-style: normal; */
	font-weight: 700;
	font-size: 1.3rem;
	line-height: 24px;
    text-align: center;
    padding-top: 20px;
    letter-spacing: 5%;

	color: #000000;
}
.container {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.title {

	/* font-family: var(--main-font); */
    font-family: 'Rubic', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 0.8rem;
	line-height: 19px;

	
	letter-spacing: 0.02em;

	color: #000000;


	/* font-size  : 16px; */
	/* font-weight: 700; */
}



.react_tabs_tab {
	
	width: 30% !important;
    color: 2px solid var(--primary-color)
}


.line {
	display: flex;

	width: 428px;
	height: 15px;
	left: 0px;
	/* top: 540px; */

	background: rgba(96, 96, 96, 0.1);

	/* border: 1px solid #000000; */
}
