.search {
    min-width: 100%;
    /*background    : linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%);*/
    background: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 10px 7px 10px 43px;
    color: white;
    outline: none;

}

.div {
    position: relative;
    /*width: 75%;*/
}

.searchIcon {
    position: absolute;
    left: 13px;
    top: 10px;
}

.search::placeholder {
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    /* color: white; */
}

.deleteIcon {
    position: absolute;
    right: 12px;
    height: 12px;
    top: 14px;
}