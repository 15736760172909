.contentClassName { 
    display: flex;
    flex-direction: row;
    justify-content : space-between;
    padding-right: 10px;

} 

.content {
    width: auto;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--dark-background-color);
    border-radius: 10px;
    gap     : 10px;
}

.active {
    border-color: var(--primary-color);
}

.title {
    font-weight: 700;
    font-size: clamp(12px, 3.2vw, 14px);
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    letter-spacing: 0.075em;
}

.priceContainer {
    display: flex;
    /* flex-wrap: nowrap; */
    white-space: nowrap;
    margin-left: -20px;

    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
    font-family: 'Rubic', sans-serif;
    font-style: normal;
    font-weight: 700;
    gap : 10px;


}

.price {
    flex-wrap: nowrap;
    font-size: clamp(18px, 3.8vw, 20px);
    line-height: 12px;
    display: flex;
}

.minQuantity {
    font-size: clamp(10px, 3.2vw, 14px);
    color: #606060;
}

@media screen and (max-width: 370px) {
    .price {
        font-size: 18px;
    }
}

@media screen and (max-width: 360px) {
    .price {
        font-size: 17px;
    }
}

@media screen and (max-width: 330px) {
    .price {
        font-size: 15px;
    }
}