.wrapper {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 130px;
}

.name, .address {
    padding-left: 10px;
    font-family: 'Rubik', sans-serif;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 10px;
}

.name {
    font-weight: 500;
    font-size: 1.1rem;
}

.address {
    font-weight: 300;
    font-size: 0.7rem;
}

.detailsContainer {
    flex-direction: row;
    display: flex;
    text-align: center;
    margin: 10px -20px;
}

.details {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 45px;
    background-color: rgba(237, 237, 237, 1);
    border-radius: 10px;
    margin: 2px;
    padding-top: 5px;
    text-align: center;
    align-items: center;
    justify-content: center; 
    line-height: 2.5;
}

.detailsLabel, .detailsValue {
    display: flex;
    font-family: 'Rubik', sans-serif;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 1);
}

.detailsLabel {
    font-weight: 600;
    font-size: 0.6rem;
}

.detailsValue {
    font-weight: 100;
    font-size: 0.5rem;
}

.changeContainer {
    display: flex;
    justify-content: center;
    color: rgba(79, 140, 231, 1);
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    margin: 15px;
}

.performanceContainer {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
}

.performanceLabel {
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.performance {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    background-color: rgba(237, 237, 237, 1);
    border-radius: 5px;
}

.performanceItemLabel, .performanceItemValue {
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    text-align: right;
    color: rgba(0, 0, 0, 1);
}

.performanceItemLabel {
    width: 47%;
    font-size: 0.7rem;
    flex-wrap: nowrap;
}

.performanceItemValue {
    width: 58%;
    font-size: 0.9rem;
    text-align: center;
}
