.wrapper {
    padding-bottom: 15px;
}


.commentContent {
    padding: 20px 15px;
    display: grid;
    grid-template-columns: auto 30%;
    grid-template-rows: auto auto auto;
    text-align: left;
    row-gap: 17px;
}

.comment {
    grid-column: span 2;
}

.commentContent div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.commentContent p {
    font-size: 12px;
    line-height: 14px;
}

.ratingContainer {
    justify-self: center;
}

.ratingDate {
    font-size: 8px !important;
    line-height: 8px;
    color: #606060;
    text-align: center;
}

.imageContainer {
    display: flex;
    gap: 5px;
    flex-direction: row;
    padding: 0 15px;
    width: 100%;
    overflow: scroll;
}

.imageContent {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
}

.imageContent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.editContainer {
    display: flex;
    justify-content: space-between;
    padding: 17px 15px;
}

.editContainer div {
    /*width: 30%;*/
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

}

@media screen and (max-width: 370px) {
    .editContainer div {
        font-size: 10px;
    }

}

.edit {
    color: var(--primary-color);
    cursor: pointer;
}

.delete {
    color: var(--shared-color);
    cursor: pointer;
}

.answer span {
    color: var(--primary-color);
}

.linkContainer {
    padding: 0 15px;
}