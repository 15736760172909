



.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.BasketToCardButtonContainer {
    width: 100%;
}
.BasketToCardButton {
    width: 100%;
    margin-top: 10px;
    /*background: linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%);*/
    background-color: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.075em;
    padding: 7px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}