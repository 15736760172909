.wrapper {
    flex-grow: 1;
  }
  
  .registeredInput {
    position: relative; /* Add position relative to the input container */
    width: 100%;
    height: 50px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background-color: rgba(237, 237, 237, 1);
    /* background-color: #EDEDED; */
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    text-align: left; /* Align the text inside the input to the left */
  }
  
  .registeredInput:focus-visible {
    outline: none;
  }
  
  .registeredInput::placeholder {
    position: absolute; /* Position the placeholder absolutely */
    top: 0; /* Place it at the top */
    left: 10px; /* Add some left padding */
    font-family: var(--main-font);
    color: rgba(96, 96, 96, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /*padding: 0 14px;*/
  }
  
  input:read-only::placeholder {
    /*background-color: #c7c7c7;*/
    color: var(--dark-background-color);
  }
  