.container{
    /* border: 1px solid var(--primary-color); */
}
.cost {
    font-weight: 700;
    font-size: clamp(16px, 3.8vw, 20px);
    text-align: left;
    padding-bottom: 2px;
}

.jointInfo {
    font-size: clamp(8px, 2.8vw, 10px);
    display: flex;
    align-items: center;
}

.highlighting {
    color: var(--primary-color);
}

