.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; 
    background: rgba(96, 96, 96, 0.1);
    /* padding-bottom: 80px; */
    align-items: center; 
    justify-content: center;
    font-family: 'Rubic', sans-serif;
  }

  .button{
    width: 100%;
    height: 50px;
    background-color: #4F8CE7;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }