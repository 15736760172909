.wrapper {
    padding: 70px 15px 10px;
    font-weight: 400;
    font-size: 20px;
    font-family: var(--secondary-font);
}

.goBack {
    height: 21px;
    width: 25px;
    position: absolute;
    left: 15px;
    cursor: pointer;
}

.container {
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.075em;
}