.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    /* background: rgba(237, 237, 237, 1);  */
    margin-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.headerContainer{
    display: flex;
    flex-direction: row;
    /* background: white; */
    /* justify-content: space-between; */
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    /* justify-content: center; */
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;  
    width: 100%;
    font-size: clamp(8px, 3.0vw, 12px);
}


.form{
    background: rgba(96, 96, 96, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap : 15px; 
    /* border: 1px solid red; */
}


.formItem{
    background: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    gap : 10px; 
    padding-top: 10px;
    padding-bottom: 10px;
}

.label{
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;

}

.info{
    display: flex;
    flex-direction: column;
    font-family: 'Rubik', sans-serif;
    font-size: clamp(6px, 2.8vw, 10px);
    font-weight: 400;
    /* line-height: 12px; */
    /* letter-spacing: 0.05em; */
    text-align: left;
    background: var(--primary-color);
    border-radius: 10px;
    color: white;
    max-height: 20vw;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.avatarContainer {
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10%;
    background: url('../../../../../assets/png/camera.png') ;
    background-color: var(--light-background-color);
    background-size: 40%;

    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* object-fit: cover; */
    


}


.videoContainer {
    position: relative; 
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10%;
    background: url('../../../../../assets/svg/video_camera.svg') ;
    background-color: var(--dark-background-color);
    /* color: var(--dark-background-color); */
    background-size: 40%;

    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */


}


.avatar {
    width: 100%;
    height: 100%;

    object-fit: cover;
}
.newAvatar {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    object-fit: contain;
    cursor: pointer;
}
.newAvatarHiden{
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    object-fit: contain;
    cursor: pointer;
    color : var(--dark-background-color);
    /* border: 5px solid red; */

}



.noPhoto {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 22px;
}

.mediaContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap : 10px;
    /* justify-content: space-between; */
}

.mediaText{
    display: flex;
    color: var(--primary-color);
    align-items: center;
    font-size: clamp(8px, 3.1vw, 12px);
    font-weight: 400;
}


.buttonContainer{
    background-color: #FFFFFF;
    color: #FFFFFF;
    width: 100%;
    height: 15vw;
    align-items: center;
    display: flex;
}

.button{
    display: flex;
    /* margin-top: 15px; */
    background-color: #FFFFFF;
    width: 99%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    justify-content: center;
    align-items: center;
    /*margin: 1%;*/
    cursor: pointer;
}

.button:disabled {
background: rgba(189, 189, 189, 0.6);
cursor: default;
}


.deleteButton{
    position: absolute;
    top: 7;
    right: 0;
    padding-top: -3px;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border-radius: 10px;
}