
.modalContainer {
  
    margin-bottom: 60px;
    height: auto;
    max-height: 90%;
    /* overflow-y: scroll; */
    width: 100%; 
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    left :0;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
    z-index: 12;
  }
  
  .closeLineContainer {
      padding: 10px 0 0;
  }
  
  .closeLine {
      width: 30px;
      height: 3px;
      border-radius: 2px;
      background: #606060;
      margin: 0 auto;
  }
  
  /* .checkmark {
      display: inline-block;
      height: 15px;
      width: 15px;
      border-bottom: solid 3px #606060;
      border-right: solid 3px #606060;
      position: relative;
      right: -42%;
      transform: rotate(45deg) translateY(-12px);
  } */
  
  .modalHeader{
      display: flex;
      align-items: flex-start;
      text-align: left;
      padding: 10px 20px;
      background-color: #fff;
      font-style: 'Rubic', sans-serif;
      letter-spacing: 5%;
  }
  
  .modalTitle{
      font-size: clamp(12px, 2.6vw, 14px);
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      line-height: 18px;
      
  }
  
  .orderId{
      /* display: flex; */
      /* align-items: center;      */
      padding-left: 5px;
      padding-right: 5px;
      font-size: clamp(10px, 2.6vw, 12px);
      font-weight: 600;
      color: rgba(79, 140, 231, 1);
      line-height: 18px;
  }
  
  
  
  .modalBody{
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      background-color: #fff;
      gap: 5px;
  }
  
  
  
  .itemImage{
      /* margin-right: 15px;  */
      width: 60px;
      height: 60px;
      gap : 7px;
      display: flex;
      flex-direction: column;
      }
  
  
  .itemImage img{
          /* width: 147px; */
          height: 60px;
          object-fit: cover;
          border-radius: 5px;
          /* margin-right: 15px; */
      }
  
  
  .quanityContainer{
      /* display: flex;
      width: 60px;
      flex-direction: column;
      align-items: center;
      justify-content: center; */
      /* padding: 10px 10px 10px; */
      font-family: 'Rubic, sans-serif';
      font-weight: 400;
      font-size: clamp(4px, 2.4vw, 7px);
  
      /* line-height: 1.2em; */
  
  }
  
  .orderQuantity{
      /* padding-left: -5px; */
      font-size: clamp(4px, 2.4vw, 7px);
      word-spacing: -2px;
  }
  
  .orderSum{
      font-weight: 600;
      }
      
      
  .reason{
      display: flex;
      text-align: left;
      font-size: clamp(8px, 2.8vw, 10px);
      font-weight: 600;
      line-height: 1.2em;
      padding-left: 20px;
      /* margin-bottom: 10px; */
  }
  
  .buttonContainer{
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      background-color: #fff;
      gap: 10px;   
      flex-wrap: wrap;
  }
  
  .dealButton{
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 'Rubic', sans-serif;
      /* max-width: 30%;   */
      height: 25px;
      background-color: rgba(237, 237, 237, 1);
      border: 2px solid rgba(181, 181, 181, 1);
      border-radius: 10px;
      line-height: 12px;
      letter-spacing: 0px;
      color: black;
      font-weight: 500;
      font-size: clamp(8px, 2.6vw, 12px);
      padding: 7px;
      /* cursor: pointer; */
      /* margin-top: 20px; */
  }
  
  .dealButton.active{
      border-color: rgba(79, 140, 231, 1);
  }
  
  .textarea{
      width: 88%;
      height: 70px;
      border-radius: 10px;
      border: 2px solid rgba(181, 181, 181, 1);
      font-family: 'Rubic', sans-serif;
      font-weight: 400;
      font-size: clamp(8px, 2.6vw, 12px);
      line-height: 12px;
      letter-spacing: 0px;
      resize: none;
      outline: none;
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px;
      background: rgba(237, 237, 237, 1);
  
      }
  .textarea::placeholder{
      color: rgba(96, 96, 96, 1);
  
  }
  
  
  
  
  .totalButtons{
      display: flex;
      align-content: center;
      justify-content: center;
      /* gap : 10px;     */
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;    
      
  }
  
  
  
  .totalButton{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 90%;
      background: rgba(231, 79, 79, 1);
  
      border-radius: 10px;
      color: rgba(255, 255, 255, 1);
      font-family: "Rubik", sans-serif;
      font-weight: 600;
      font-size: clamp(12px, 3.2vw, 16px);
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      border: none;
      /* margin-left: 10; */
      /* margin-bottom: 10px; */
  }
  
  .totalButton:disabled{
      background: rgb(245, 162, 162);
      color: rgba(255, 255, 255, 1);
      cursor: not-allowed;
  }
  
  .proceedBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 90%;
      background: rgba(231, 79, 79, 1);
  
      border-radius: 10px;
      color: rgba(255, 255, 255, 1);
      font-family: "Rubik", sans-serif;
      font-weight: 600;
      font-size: clamp(12px, 3.2vw, 16px);
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      border: none;
    }