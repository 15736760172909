.wrapper {
    padding: 90px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding: 10px;

}

.successWrapper {
    padding-top: 120px;
}

.form {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 2.6em;
    line-height: 1.8em;
    text-align: center;
    margin: auto 0;
}

.text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1.8em;
    line-height: 2.4em;
    text-align: center;
}