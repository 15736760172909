.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.imageContainer {
    width: 75px;
    height: 75px;
    border-radius: 10px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.addImageContainer {

    width: 75px;
    height: 75px;
    background-color: var(--light-background-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addImageContainer img {
    cursor: pointer;
}

.iconContainer {
    position: absolute;
    width: 20px;
    height: 25px;
    cursor: pointer;
    background-color: rgba(256, 256, 256, 0.5);
    border-radius: 4px;
    right: 5px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconContainer img {
    width: 90%;
}