.item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 106px;
    row-gap: 5px;
}

.slide {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    width: 35%;
    height: 106px;

    overflow:hidden;
    /* border: 2px solid green; */
  
}

.GoodsMainSlider {
    overflow: hidden;
    
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;    

    width: 100%;          
    height: 100%;         
}

.sliderImage {
    max-width: 150px ;          
    max-height: 106px;         
    object-fit: cover;  
}


.ProductContent {
    /* padding: 6px 7px; */
    font-family: 'Rubik', sans-serif;
    text-align: left;
    padding-left: 5px;
    /* max-width: 70%; */

    /* border: 2px solid red; */
    /* padding-right: -40px; */
}



.PriceContainer {
    display: flex;
    align-items: flex-end;
    column-gap: 10px;
}

/* .jointPrice {
    font-size: 1.4em;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
} */

/* .ordinaryPrice {
    font-size: 1em;
    font-weight: 400;
    font-family: "Exo 2", sans-serif;
    text-decoration: line-through;
} */

.Price {
    font-size: 1.4em;
    font-weight: 600;
    font-family: "Rubic", sans-serif;
    text-align: left;
}


/* 
.itemInnerContainer {
    display: flex;
    justify-content: space-between;
    border: 2px solid blue;
} */



.newProduct,
.someOne {
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 1px;
    line-height: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;


}

.someOne {
    background: rgb(59, 201, 210);
    padding: 4px 0 24px;
    border-radius: 10px 10px 0 0;
    z-index: 2;
}

.newProduct {
    background: rgb(255, 72, 72);
    padding: 4px 10px;
    border-radius: 10px 10px 0 0;
    z-index: 4;
    display: flex;
    justify-content: space-between;
}

.promoTitle {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
}

.promoPrice {
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
}




.about {
    font-size: 1em;
    font-weight: 400;
    padding: 9px 0;
}

.subCategoryContent{
    font-weight: 400;
    font-size: clamp(8px, 10px, 12px);
    color: var(--dark-background-color)

}
.shopNameContent{
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: clamp(8px, 10px, 12px);

}


.approveImage{
    /* width: 20px; */
    /* height: 20px; */
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    margin-left: 5px;


}

.ratingContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    width: 100%;  
    margin-left: -15px;
    margin-top: 10px;
}




@media (max-width: 370px) {
    .someOne,
    .promoTitle {
        font-weight: 500;
        font-size: 7px;
    }

    .promoPrice {
        font-weight: 600;
        font-size: 10px;
    }
}
























