



.header{
    margin-top: 10px;
    font-size : clamp(10px, 3,2vw, 14px);
    font-family: 'Rubic', sans-serif;
    letter-spacing: 0.5px;
    margin-bottom: 5px;

}


.step{
    font-weight: 400;
}

.infoContainer{
    margin-top: 15px;
    background: var(--primary-color);
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    max-height: 30vw;
    /* justify-items: left; */
    align-items: center;

}

.infoText{
    font-family: "Rubik", sans-serif;
    font-size: clamp(6px, 2.8vw, 10px);
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

}


.buttonText{
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    font-size: clamp(10px, 3.4vw, 14px);
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 500;
    cursor: pointer;


}

.button{
    position: fixed;
    bottom: 80px;
    display: flex;
    background-color: #FFFFFF;
    transform: translateX(-50%); 
    left: 50%;
    min-width: 93%;
    max-width: 450px;

    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    /* justify-items: center; */
    justify-content: center;
    align-items: center;
    /*margin: 1%;*/
    cursor: pointer;
}

.button:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
    }
    


.menuContainer {
    position: fixed;  
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%); /* this centers the modal */
    width: 100%;       
    
    max-width: 450px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    font-family: 'Rubic', sans-serif;
    gap: 10px;
    border-top: 1px solid rgba(96, 96, 96, 1);
    background-color: white;
}


.menuLabel{

    display: flex;
    font-size: clamp(8px, 3.4vw, 12px);
    font-weight: 501;
    line-height: 20px;
    letter-spacing: 0.1em;
    /* justify-content: flex-start; */


}

.menuInfo{
    height: 30px;
    display: flex;
    font-size: clamp(8px, 3.2vw, 10px);
    font-weight: 400;
    /* line-height: 20px; */
    letter-spacing: 0.075em;
    justify-content: flex-start;
    text-align: left;
    color: rgba(96, 96, 96, 1);

}

.formInfo{
    display: flex;
    flex-direction: column;
    gap : 10px
}


.input{
    border: none;
    height: 35px;
    width: 99%;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    font-size: clamp(16px, 3.4vw, 17px);
    padding-left: 10px;

}


.closeLineContainer {
    padding: 10px 0 10px;
}
.closeLine {
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: #606060;
    margin: 0 auto;
    cursor: pointer;
}
/* .checkmark {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-bottom: solid 3px #606060;
    border-right: solid 3px #606060;
    position: relative;
    right: -42%;
    transform: rotate(45deg) translateY(-12px);
} */

.addCategory{
    display: flex;
    margin-top: 15px;
    width: 99%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    justify-content: center;
    align-items: center;
    /*margin: 1%;*/
    cursor: pointer;
}

.addCategory:disabled {
background: rgba(189, 189, 189, 0.6);
cursor: default;
}







.labelContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding-left: 10px; */
    padding-right: 10px;
    /* margin-top: 10px; */
   
    }

.label{
    font-size: clamp(10px, 3.2vw, 12px);
    padding-left: 10px;
}

.purposeLabel{

    font-weight: 300;
    color: rgba(63, 56, 56, 0.6)
}




.mediaContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.imageContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.avatar {
    
    object-fit: 80%;
    width: 100%;
    height: 100%;
}
.newAvatar {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    cursor: pointer;

}
.noPhoto {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 22px;
}
.avatarContainer {
    display: flex;
    flex: 0 1 calc(20% - 10px);
    position: relative;
    overflow: hidden;
    /* width: 70px; */
    /* height: 70px; */
    border-radius: 10%;
    background: url('../../../../assets/png/camera.png') ;
    background-color: var(--light-background-color);
    background-size: 40%;

    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */


}




.imageInfo{
    display: flex;
    margin: 10px;
    flex-direction: column;
    justify-items: left;
    align-items: flex-start;
    
}

.imageLabel{
    font-size: clamp(10px, 3.2vw, 12px);
    font-weight: 500;
    /* line-height: 20px; */
    letter-spacing: 0em;
    padding-left: 10px;
}

.imageText {
    color :rgba(96, 96, 96, 1) ;
    font-weight: 300;
    padding-left: 10px;


}
.imageRequired{
    color :rgba(96, 96, 96, 1) ;
    font-weight: 300;
    /* line-height: 20px; */
    padding-left: 10px;
    padding-bottom: 5px;

}

.formContainer{
    margin-top: 10px;
}

.formLabel{
    display: flex;
    align-self: flex-start;
    padding-left: 10px;
    margin-bottom: 10px;
}

.sizeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 10px; 
    width: 100%;
    /* max-width: 600px;  */
}

.sizeField {
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.formLabel {
    font-weight: bold;
    color: rgba(96, 96, 96, 1);

}

.sizeLabel{
    display: flex;
    align-self: flex-start;
    padding-left: 10px;
    color: rgba(96, 96, 96, 1);
    
}

.deletePurpose{
    color : red;    
    font-weight: 300;
}

.purposeContainer{
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    /* margin-bottom: 90px; */
    overflow: scroll;
    /* gap: 30px */
}

.variantsContainer{
    margin-bottom: 90px;
}


.addVariant{
    color: var(--primary-color);
    font-family: 'Rubik'  sans-serif;
    font-size: clamp(14px, 3,4vw, 16px);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 15px;
    
}


.error{
    color: red;
}

.deleteButton {
    width: 10px;
    max-height: 10px;
    
    cursor: pointer;
}


.backArrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.stepContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap : 10px;
    
}