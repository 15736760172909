.wrapper {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--dark-background-color);
}

.activeCircle {
    border: 4px solid var(--primary-color);
    /*background: linear-gradient(#FFF, #fff) padding-box,*/
    /*linear-gradient(90deg, #ef6666 0%, #0628f1 49.48%, #ef72f2 100%) border-box;*/
}

.row {
    text-align: left;
}

.text {
    font-size: 14px;
    line-height: 18px;
    padding: 5vw;
}

.title {
    font-size: 14px;
    line-height: 12px;
    padding: 14px;
}

.container {
    padding: 5vw;
    display: flex;
    flex-direction: column;

}