.wrapper {
    position: relative;
}

.textContainer {
    padding: 0 15px;
}

.progressBarWrapper {
    position: relative;
    margin: 16px 0 26px;
}

.progressBarBackground {
    width: 100%;
    height: 25px;
    background-color: var(--dark-background-color);
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
}

.progressBarValue {
    height: 25px;
    background-color: var(--primary-color);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}

.progressBarContent {
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ratingContainer {
    position: absolute;
    bottom: 0;
    right: 20px;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
