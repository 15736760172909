.Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;

}

.BottomBorder {
    width: 100%;
    height: 1px;
    background: linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%);
}

h3 {
    font-size: 1.6em;
    text-align: left;
    font-weight: 600;
    /*padding-left: 20px;*/
}

.p {
    font-size: clamp(14px, 3.2vw,  16px);

    font-weight: 400;
    margin: 20px auto 20px;
    text-align: center;
    justify-content: center;
}

.img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: calc(50% - 5px);
}

.newShop,
.hotPrice {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 70px;
    width: 188px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    width: calc(50% - 5px);
}

.newShop {
    background: linear-gradient(180deg, #EC5555 0%, #C343C6 100%);
}

.hotPrice {
    background: linear-gradient(180deg, #FFF500 0%, #E90202 100%);
}

.textShop,
.textHotPrice {
    font-size: 1.4em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    margin: 0;
    line-height: 86%;
    position: absolute;
    left: 9px;
    bottom: 10px;
}

.textShop {
    width: 80px;
}

.textHotPrice {
    width: 80px;
}

.imgNewShop,
.imgHopPrice {
    position: absolute;
    bottom: 0;
}

.imgNewShop {
    right: 5px;
}

.imgHopPrice {
    right: 10px;
}

.title {
    padding: 1.5vh;
    font-weight: 400;
    font-family: 'Rubic', sans-serif;
    font-size: clamp(14px, 3.2vw,  16px);
}