.wrapper {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: space-between;
    padding: 16px 19px;
    background: #fff;
    cursor: pointer;
}

.content {
    display: flex;
    align-items: center;
    font-family: inherit;
}

.title {
    text-transform: uppercase;
    font-family: inherit;
}

.wrapper img {
    width: 13px;
}