.wrapper{
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	padding: 10px 15px;
}
.container{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.align img{
	width: 40px;
	height: 40px;
	object-fit: cover ;
}
.link{
	width: 13px;
	height: 11px;
}
.title{
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
}
.align{
	display: flex;
	align-items: center;
	column-gap: 10px;
}