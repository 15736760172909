.itemContainer {
    font-family: var(--secondary-font);
    display: flex;
    gap: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    padding: 0 15px;
}

.imgContainer {
    width: 106px;
    height: 106px;
    aspect-ratio: 1/1;
    overflow: hidden;
    margin: auto 0;
    border-radius: 10px 0 0 10px;
}

.imgContainer img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.itemContent {
    flex-grow: 1;
    text-align: left;
}


.name {
    font-size: 14px;
    padding: 5px 0 5px;
}

.price {
    padding: 5px 0 5px;
    font-size: 12px;
}

.shop {
}

.modifier {
}

.modifierValue {
    color: #606060;
}

.status {
    font-size: 9px;
    line-height: 12px;
    color: #FFFFFF;
    padding: 8px 0 6px;
    border-radius: 10px 10px 0 0;
    max-width: 77px;
    text-align: center !important;
}



