.container {
    background-color: var(--dark-background-color);
    font-family: 'Rubic', sans-serif;
    letter-spacing: -2px;
    word-spacing: -2px;
    color: white;
    display: flex;
    height: 28px;
    justify-content: flex-start;
    /* padding: 5px 16px; */
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    gap: 3vw;
    line-height: 8px;
    font-size: clamp(6px, 2.8vw, 8px);
    display: flex;
    align-items: center;
    width: 100%;
}

.active {
    background-color: var(--primary-color);
}

.timerContainer {
    display: flex;
    gap: 2px;
    align-items: center;
}

.timeContent {
    white-space: nowrap;
}

.box {
    display: flex;
    align-items: center;
    gap: 3px;
}

.box > span {

}