.react-tabs {
    width: 100% !important;
    /*margin-bottom: 15px;*/
}

.react-tabs__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #606060;
    padding: 15px !important;
}

.react-tabs__tab div {
    border-bottom: 2px solid var(--dark-background-color);
}

.react-tabs__tab--selected div {
    border-bottom: 2px solid var(--primary-color) !important;
    /*background   : linear-gradient(#ffffff, #ffffff) padding-box,*/
    /*	linear-gradient(90deg, #f00606 0%, #0628f1 49.48%, #ef72f2 100%) border-box;*/

}

.react-tabs__tab--selected {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #000000;
    border: none !important;

}

.react-tabs__tab-list {
    border: none !important;
    margin: 0 !important;
}

.react-tabs__tab-panel--selected {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #000000;
}