.wrapper {
    padding-right: 15px;
}


.itemContainer {
    font-family: var(--secondary-font);
    display: flex;
    gap: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
}

.imgContainer {
    width: 106px;
    height: 106px;
    aspect-ratio: 1/1;
    overflow: hidden;
    margin: auto 0;
    border-radius: 10px 0 0 10px;
}

.imgContainer img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.itemContent {
    flex-grow: 1;
    text-align: left;
}


.name {
    font-size: 12px;
    padding: 5px 0 5px;
}

.price {
    padding: 5px 0 5px;
    font-size: 12px;
}

.shop {
}

.modifier {
}

.ratingContainer {
    display: flex;
    justify-content: flex-end;
}

.optionalText {
    color: var(--primary-color);
    font-size: 12px;
    text-align: left;
    padding: 20px 15px 16px;
}

.unknownComment {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 8px;
}

.imageContainer {
    padding: 10px 15px;
}

.imageContainer p {
    padding-bottom: 10px;
    font-size: 12px;
    font-family: var(--secondary-font);
    line-height: 16px;
    letter-spacing: 0.6px;
    text-align: left;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px;
}

.applyButtonContainer {
    padding: 0 15px;
}

.linkContainer {
    margin-top: 6px;
    font-size: 10px;
    font-family: var(--main-font);
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
}

.linkText {
    color: #606060;
}

.link {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}

.localModalContent {
    max-height: 60vh;
    overflow-y: scroll;
}