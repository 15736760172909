.container {
    margin-top: 5px;

    margin-bottom: 4%;
    display: flex;
    flex-direction: column;
    gap :20px;
    /* border: 1px solid; */
}

.shopContainer {
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items vertically in the center */
    /* border: 1px solid; */
    gap : 10px;

}


.imageContainer {
    display: flex;
    width: 100px    ;
    height: 100px;
    /* border-radius: 10px; */
    /* border: 1px solid; */
}

.photo {
    width: 100px;
    height: 100px;
    /* border-radius: 10px; */
}

.infoContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;

    align-items: flex-end;
}

.infoText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}




.premiumText {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: var(--primary-color);
}

.name {
    font-weight: 500;
    font-size: clamp(12px, 3.2vw, 14px);
    line-height: 12px;

    display: flex;
    align-items: center;


}

.info {

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    display: flex;
    align-items: center;
}


.likedContainer {
    margin-top: 10px;
    margin-right: 10px;
    align-self: flex-start;
    
    /* border : 1px solid #4F8CE7; */
}




.textContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.boxContainer {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    /* border: 1px solid; */
}



.ratingContainer {
    align-items: flex-start;
    /* wrap : nowrap; */
    display: flex;
    flex-direction: row;
    color : var(--grey-text-color);
    align-items: center;
    gap : 5px;
    padding-left: -10px;
    
}

.sharedContainer {
    height: 25px;
    display: flex; 
    /* width: 20%; */
    align-self: flex-start;
    width: 80px;
} 

.starCount{
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(6px, 2.6vw, 8px);
    /* line-height: 12px; */
    /* identical to box height, or 120% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}