.wrapper {
    margin-top: 0px;
    padding: 0 13px 130px;
    position: relative;
    background-color: #ffffff;
    z-index: 11;
    max-height: 100vh;
}


.title {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 27px;
    letter-spacing: 0.075em;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #606060;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.innerCircle {*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    border-radius: 50%;*/
/*    background-color: transparent;*/
/*}*/

.cityContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px 0;
}

.mainCity {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.075em;
}

.secondaryCity {
    font-weight: 600;
    font-size: 10px;
    color: #606060;
    letter-spacing: 0.075em;
}

.searchCity::placeholder {
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    letter-spacing: 0.075em;
}

.inputContainer {
    display: flex;
    flex-direction: row;
}

.searchCity {
    width: 100%;
    border-radius: 10px 0 0 10px;
    border: none;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.075em;
    padding: 12px 14px;
    background-color: #EDEDED;
    outline: none;
    flex-grow: 1;
}

.searchContainer {
    display: flex;
    justify-content: flex-end;
    margin: 0 3%;
}

.inputContainer {
    flex-grow: 1;
    position: relative;
}

.clearSvg {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 13px;
    filter: invert(60%) sepia(1%) saturate(2829%) hue-rotate(303deg) brightness(116%) contrast(86%);
}

.find {
    margin: 0 3% 0 1px;
    border-radius: 0 10px 10px 0;
    border: none;
    padding: 12px 3px 12px 24px;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.075em;
    color: #606060;
}

.findContainer {
    position: relative;
}

.searchSvg {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 50px;
    top: 13px;
    filter: invert(60%) sepia(1%) saturate(2829%) hue-rotate(303deg) brightness(116%) contrast(86%);
}

.headerContainer {
    position: fixed;
    top: 8px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 18px 0 18px;
}

.content {
    margin-top: 70px;
}

.active {
    border: 4px solid var(--primary-color);
    /*background: linear-gradient(#FFF, #fff) padding-box,*/
    /*linear-gradient(45deg, #ef6666 0%, #0628f1 49.48%, #ef72f2 100%) border-box;*/
}

/*.innerCircleActive {*/
/*    background: linear-gradient(0deg, #ef6666 0%, #0628f1 49.48%, #ef72f2 100%);*/
/*}*/

.applyContainer {
    padding: 15px 20px 20px;
    width: 100%;
    position: fixed;
    bottom: 60px;
    left: 0;
    background: #FFF;
}

.applyButton {
    width: 80%;
    background: #4F8CE7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: 'Exo 2', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
}

.applyButton:disabled {
    background: var(--light-background-color);
}

.hidden {
    display: none;
}

.show {
    display: block;
}