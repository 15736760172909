.simpleButton {
    width: 100%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    border: none;
    font-family: var(--secondary-font);
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    padding: 12px;
    letter-spacing: 0.075em;
    color: white;
    margin: 1.5% 0;
    cursor: pointer;
    text-align: center;
}

.simpleButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}