.wrapper {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    position: relative;
    background-color: #fff;
    row-gap: 1%;
    overflow-y: scroll;
}

.topContent {
    /*position: fixed;*/
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    background-color: white;
    /*position: fixed;*/
    height: auto;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
}

.bottomContent {
    position: relative;
    z-index: 19;
    overflow-y: scroll;
    background-color: white;
    /*min-height: 100vh;*/
    padding-bottom: 60px;
    /*padding-top: 300px;*/
}

.registeredPhoneContainer {
    display: flex;
    align-items: flex-end;
    padding-right: 14px;
}

.avatarContainer {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px auto;
    background-color: var(--light-background-color);
    display: flex;
    justify-content: center;
    align-items: center;

}

.avatar {
    object-fit: cover;

    width: 100%;
    height: 100%;
}

.name {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 18px;
}

.change {
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 24px;
    cursor: pointer;
}

.newAvatar {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    object-fit: contain;
    cursor: pointer;
}

.buttonContainer {
    padding: 20px;
}