.root {
  font-family: "Rubik", "sans-serif";
  background-color: #6060601A;
  padding-bottom: 40px;
}

.pageTitle {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #000;
  background-color: #fff;
  padding: 10px 15px 20px;
  text-transform: uppercase;
}

.bonusesCountSection {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 15px;
  margin-bottom: 15px;
}

.marketBonus {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.marketBonusTitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.marketBonusValue {
  background: #4F8CE7;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 8px 0;
  border-radius: 10px;
  margin-top: 5px;
}

.shopBonuses {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sellerBonus {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-bottom: 16px;
}

.sellerBonusTitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.sellerBonusValue {
  background: #000000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 8px 0;
  border-radius: 10px;
  margin-top: 5px;
}

.expiresSoon {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.expiresSoonTitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.expiresSoonValue {
  background: #E74F4F;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 8px 0;
  border-radius: 10px;
  margin-top: 5px;
}

.bonusHelp {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 15px;
}

/* Bonus list section */

.bonusListSection {
  padding: 0 20px;
  background-color: #fff;
  margin-bottom: 15px;
}

.bonusCard {
  display: flex;
  padding: 15px 0;
}

.bonusCardImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 50px;
}

.bonusCardInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
}

.cardInfo {
  display: flex;
  flex-direction: column;
}

.shopStatus {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #4F8CE7;
}

.shopName {
  font-size: 14px;
  font-weight: 400;
  /*margin-bottom: 5px;*/
}

.shopDescription {
  font-size: 10px;
  font-weight: 400;
}

.shopBonusCount {
  font-size: 14px;
  font-weight: 400;
  color: #4F8CE7;
  display: flex;
  align-items: center;
}

.shopBonusCount span {
  margin-left: 5px;
}

.black > * {
  color: #000;
  fill: #000;
}

.blue > * {
  color: #4F8CE7;
  fill: #4F8CE7;
}

.red > * {
  color: #E74F4F;
  fill: #E74F4F;
}

.shopBonusCountIcon {
  width: 25px;
  margin-right: 5px;
}


/* History section */

.historySection {
  background-color: #fff;
  padding: 15px 20px 0;
  margin-bottom: 50px;
}

.historySectionTitle {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 10px;
}

.historySectionContent {

}

.tabs {
  justify-content: space-between;
  display: flex;
}

.historyItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.historyCardImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.historyCardInfo {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.historyShopName {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.historyShopStatus {
  font-size: 10px;
  font-weight: 400;
  color: #4F8CE7;
}

.historyBonusAmount {

}

.divider {
  width: 100%;
  height: 1px;
  background-color: #4F8CE7;
}

/* FAQ */

.faq {

}

.faqTitle {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  width: 100%;
  margin: 15px 0 20px;
}
