.wrapper {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    /*display: flex;*/
    /*flex-direction: column;*/
    padding: 16px 19px;
    background: #fff;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #EFEFEF;
    border-radius: 15px;
    padding: 0 20px;

}

.link {
    display: flex;
    justify-content: center;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 12px;
    border-bottom: 1px solid #BDBDBD;
}

.link:last-child {
    border-bottom: none;
}


.link:first-child {
    color: red;
}
