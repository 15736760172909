.react_tabs_tab {
    width: 50% !important;
}

.pendingItemCount {
    
    height: 15px;
    width: 15px;
    background-color: var(--shared-color);
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    top: 0;
    right: 15px;
    border-bottom: none !important;
}