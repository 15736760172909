.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    position: relative;
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    gap : 10px;
    font-family: 'Rubic, sans-serif';
    /* padding-left: 10px; */
    overflow-y: scroll;
    margin-bottom: 80px;
}

.title {
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: clamp(15px, 5vw, 20px);
    line-height: 1.2em;
    text-align: center;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    background-color: white;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    /* padding-top: 10px; */
    /* padding-left: 13px; */
    /* padding-right: 13px; */

}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 10px 10px 10px;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family :'Rubik', sans-serif;

    /* justify-content: space-between; */
    /* background-color: white; */
    /* padding: 10px 10px 10px; */
}

.text{

    font-weight: 600;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
    margin-bottom: 7px;
}

.linkText{
    color :rgba(79, 140, 231, 1);

    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 10px);
}

.link{
    color :rgba(79, 140, 231, 1);
    align-self: center;
    height: 13px;

}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top:10px ;
    margin-bottom: 10px;
    background: white;
    /* row-gap: 15px; */
    padding: 10px 10px 10px;
}
.dealButton{
    width : 70px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    border-radius: 10px;
    border: rgba(181, 181, 181, 1) 2px solid;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(4px, 2.8vw, 12px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 10px; */

}
.active {
    border: 2px solid var(--primary-color);
}

.itemImage{
    /* margin-right: 15px;  */
    gap : 7px;
    display: flex;
    flex-direction: column;
    /* white-space: nowrap; */
    
    }


.itemImage img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
        /* margin-right: 15px; */
    }

.orderContainer{
    background: white;
    padding-left: 10px;
    padding-top: 10px;
    gap : 10px;
    display: flex;
    flex-direction: column; 
}

.imageContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    flex-wrap: wrap;
    
    /* padding-left: 10px; */
}

.orderInfo{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 10px);
    line-height: 1.2em;
}

.itemStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    border:  1px solid;
    border-radius: 5px;
    width: 60px;
    height: 20px;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 600;
    font-size: clamp(6px, 2.6vw, 8px);
    line-height: 1.2em;
    box-shadow: 0 0 0 1px ;

    
    color: rgba(96, 96, 96, 1);

}

.buttonContainer{
    display: flex;
    flex-direction: row;
    gap : 10px;
    /* padding: 10px 10px 10px; */
}

.buttonCancel{
    width : 25%;
    height: 40px;
    border-radius: 10px;
    border:rgba(231, 79, 79, 1) 1.9px solid;
    color: rgba(231, 79, 79, 1);

    background-color: white;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: clamp(8px, 2.6vw, 10px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    /* margin-bottom: 10px; */
    box-shadow: 0 0 0 1px #f44336;
}

.buttonGetting, .buttonDecline{
    width : 100%;
    height: 40px;
    border-radius: 10px;
    /* background: rgba(79, 231, 112, 1); */
    background: rgba(79, 140, 231, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 12px); 
    border: none;
    
}
.buttonGetting:disabled{
    background: rgba(181, 181, 181, 1) ;
    width: 75%;

}

.buttonDecline{
    background: rgba(231, 79, 79, 1) ;
}

.quanityContainer{
    display: flex;
    width: 65px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 400;
    font-size: clamp(4px, 2.6vw, 8px);

    /* line-height: 1.2em; */

}

.orderQuantity{
    /* padding-left: -5px; */
    font-size: clamp(4px, 2.4vw, 8px);
    word-spacing: -2px;
}

.orderSum{
    font-weight: 600;
}

.chooseForm{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: white; */
    align-items: flex-start;
    padding: 10px 10px 10px;
    font-family: 'Rubic, sans-serif';

}


.label{

    display: flex;
    align-self: center;
    font-weight: 600;
    font-size: clamp(12px, 3.4vw, 16px);
    margin-bottom: 15px;

}

.inputField{
    width: 100%;
    height: 35px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border : none;  
    font-weight: 400;
    font-size: clamp(8px, 2.8vw, 12px);
    line-height: 18px;
    padding-left: 10px;
    margin-bottom: 15px;
}

.inputField:focus{
    outline: none;
}   

.typeContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* padding: 10px 10px 10px; */
    font-weight: 600;
    font-size: clamp(11px, 3.0vw, 13px);
    line-height: 1.2em;
    margin-bottom: 15px;

    /* gap : 10px; */
}

/* .point{
    width: 10xp;
    height: 10px;
}
 */



