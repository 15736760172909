.container{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
    padding: 10px 10px 10px;
    margin-bottom: 80px;
    
}

.title{
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: clamp(15px, 5vw, 20px);
    line-height: 1.2em;
    text-align: center;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    background-color: white;
    margin-bottom: 10px;

}

.itemContainer{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    background-color: white;
    gap : 10px;
}

.itemInfo{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;


}

.itemImage{
    /* margin-right: 15px;  */
    width: 150px;
    height: 160px;
    display: flex;
    flex-direction: column;
    }


.itemImage img{

    width: 150px;
    height: 160px;

    object-fit: cover;
    border-radius: 5px;
    /* margin-right: 15px; */
}

.priceContainer{
    display: flex;
    flex-direction: column;
    
    align-items: flex-start ;
    /* padding-left: 10px; */
    gap : 5px;

}

.itemTotal{
    font-weight: 700;
    font-size: clamp(100%, 2.9vw, 12px);
}

.itemPrice{
    font-weight: 400;
    font-size: clamp(100%, 2.9vw, 12px);
    color: black;
}

.itemDescription{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: clamp(8px, 2.7vw, 10px);
    color: rgba(96, 96, 96, 1);
}

.itemName{
    font-weight: 500;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
}

.itemStatusContainer{
    display: flex;
    flex-direction: row;
    
    align-self: flex-end;
}

.deliveryContainer{
    display: flex;
    background: rgba(237, 237, 237, 1);
    border-radius: 5px;
    padding: 5px 5px 5px;
    align-items: center;
    text-align: center;
    gap : 5px;
    padding-right: 10px;
    height: 30px;
}

.reazonContainer{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
}

.reazonTitle{
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: clamp(10px, 2.8vw, 12px);
    line-height: 1.2em;
    align-self: flex-start;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    background-color: white;
    /* margin-bottom: 10px; */
}

.reazonButtonContainer{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-self: flex-start;
    background-color: white;
    /* padding: 10px 10px 10px; */
    flex-wrap: wrap;
    gap : 10px;
}

.reazonButton {
    display: flex;
    justify-content: center;
    align-items: center; 
    white-space: nowrap;
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 2.8vw, 10px);
    height: 25px; 
    background-color: rgba(237, 237, 237, 1);
    border: 2px solid rgba(181, 181, 181, 1);
    border-radius: 10px;
    padding: 5px;
    gap: 5px;
}


.textarea{
    width: 100%;
    height: 70px;
    border-radius: 10px;
    border: 2px solid rgba(181, 181, 181, 1);
    font-family: 'Rubic', sans-serif;
    font-weight: 400;
    font-size: clamp(8px, 2.6vw, 12px);
    line-height: 12px;
    letter-spacing: 0px;
    resize: none;
    outline: none;
    padding: 10px;
    background: rgba(237, 237, 237, 1);

    }
.textarea::placeholder{
    color: rgba(96, 96, 96, 1);

}

.active{
    border-color: rgba(79, 140, 231, 1);
}

.line{
    width: 100%;
    padding-left: -10px;
    height: 15px;
    background-color: rgba(237, 237, 237, 1);
}

.fotoRizonContainer{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
    background-color: white;
    flex-wrap: wrap;
}

.infoArea{
    display: flex;
    width: 100%;
    text-align: left;
    padding : 5px 5px 5px;

    background-color: var(--primary-color);
    border-radius: 10px;
    color : white;
    font-family: 'Rubic', sans-serif;
    font-weight: 400;
    font-size: clamp(6px, 2.4vw, 8px);
    /* flex-wrap: wrap; */
}
.avatarContainer {
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border-radius: 10%;
    background: url('../../../../../assets/png/camera.png') ;
    background-color: var(--light-background-color);
    background-size: 40%;

    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* object-fit: cover; */
    


}
.avatar {
    width: 100%;
    height: 100%;

    object-fit: cover;
}
.newAvatar {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    object-fit: contain;
    cursor: pointer;
}

.noPhoto {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 22px;
}

.mediaContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap : 10px;
    /* justify-content: space-between; */
}


.button{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; 
    white-space: nowrap;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: clamp(10px, 2.8vw, 12px);
    height: 35px; 
    background: rgba(231, 79, 79, 1);
    color: white;

    border: none;
    border-radius: 10px;
    padding: 5px;
    gap: 5px;
}

.button:disabled {
    background: rgba(128, 128, 128, 1); 
}



.fotoContainer{
    display: flex;
    flex-direction: column;
    /* text-align: left; */
    justify-content: space-between;
    gap : 20px;
    /* width: 30%; */
    background-color: white;
    flex-wrap: wrap;
}

.infoFotoContainer{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    /* gap : 8px; */
    padding-top: 5px;
    padding-bottom: 10px;
    width: 70%;
    background-color: white;
    flex-wrap: wrap;
    font-family :'Rubik', sans-serif;

}

.fotoTitle{
    font-weight : 501;
    font-size: clamp(10px, 2.8vw, 12px);
    line-height: 1.2em;
    align-self: flex-start;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    /* margin-bottom: 10px; */
}

.necessarity{
    color: rgba(79, 140, 231, 1);
    font-weight: 400;
    font-size: clamp(8px, 2.8vw, 10px);

}

.fotoInfo{
    font-weight: 400;
    font-size: clamp(4px, 2.4vw, 8px);
    color: rgba(96, 96, 96, 1);
}