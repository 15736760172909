.applyButton {
    background: var(--primary-color);
    border-radius: 10px;
    border: none;
    font-family: var(--secondary-font);
    font-style: normal;
    letter-spacing: 0.075em;
    padding: 12px 10px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.applyButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: var(--dark-background-color);
}

.check {
    background-color: var(--primary-color);
}

.valid {
    background-color: #00C62B;
}

.error {
    background-color: #FF0000;
}

.resend {
    width: 15px;
    height: auto;
}