.container {
	padding-left: 10px;
	padding-right: 10px;
}

.react_tabs_tab {
	width: 33.33% !important;
	margin-bottom: 10px;
}

.tabsWrapper {
	padding: 0 20px;
}

.displayContent {
	margin-top: 20px;
}