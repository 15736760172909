.wrapper {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    /*display: flex;*/
    /*flex-direction: column;*/
    padding: 16px 19px;
    background: #fff;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #EFEFEF;
    border-radius: 15px;
    padding-left: 20px;

}

.linksContainer:blur{
    background: red;

}

.wrapper img {
    width: 11px;
    height: auto;
    margin-right: 20px;
}

.wrapper select {
    border: none;
    background: transparent;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0 8px;
}

.wrapper select:focus-visible {
    outline: none;
}

.link {
    display: flex;
    justify-content: space-between;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #BDBDBD;
}

.link:last-child {
    border-bottom: none;
}

.mainTitle {
    font-family: var(--secondary-font);
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #000000;
    opacity: 0.9;
    text-transform: uppercase;
    padding-bottom: 15px;
}



