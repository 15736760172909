
.link {
    display: flex;
    justify-content: space-between;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #BDBDBD;
}
.notActive {
    display: flex;
    justify-content: space-between;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #BDBDBD;
    color : var(--dark-background-color);
}

.children {
    padding-right: 10px;
    color: black;
}
