.sliderImage {
    display: block;

    /* min-width: 100%; */
    margin: 0 auto;
    
    object-fit: contain;
    height: 25vh; 
    min-height: 244px;
    max-height: 275px;
    max-width: 185px;
}

.GoodsMainSlider {
    width: 100%;
    height: 80% ;
    border-radius: 10px;
    overflow: hidden;
    /* border : 2px solid black; */

}

.imageContainer {
    /* height: 25vh; */
    min-height: 244px;
    max-height: 275px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border : 2px solid black; */
}
