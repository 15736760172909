
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    background: rgba(237, 237, 237, 1); 
    margin-bottom: 80px;
}

.status{
    align-items: center;
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 155px;
    height: 30px;
    background: rgba(231, 161, 79, 1);
    color : white;
    font-size: clamp(8px, 3.0vw, 12px);   
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.headerContainer{
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.header{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px; 
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;  
}



.infoContainer, .nameContainer {
    width: 100%;
    background: white;
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    /* flex-wrap: nowrap;  Commented, remove if unnecessary */
}

.nameContainer {
    align-items: flex-start;

}



.infoRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* justify-content: align-items; */
    gap: 10px;  

}

.info, .character{
    background: rgba(237, 237, 237, 1); 
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    line-height: 12px;  
    font-family: 'Rubik', sans-serif;
}

.character{
    flex-basis: calc(50% - 10px); /* Assuming a gap of 10px */
    box-sizing: border-box;
}

.character:last-child:nth-child(odd) {
    flex-basis: 100%;
  }


.labelInfo{
    font-size: clamp(8px, 3.0vw, 12px);
}
.textInfo{
    font-size: clamp(10px, 3.2vw, 14px);
    font-weight: 500;
}

.characterRow{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
}

.mediaContainer{
    display : flex;
    flex-direction: row;
    gap: 10px;
}


.avatarContainer{
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border-radius: 10%;
    /* background: url('../../../../assets/png/camera.png') ; */
    /* background-color: var(--light-background-color); */
    /* background-size: 40%; */

    background-repeat: no-repeat;
    background-position: center;
    display: flex;

}

.avatar {
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.label{
    display: flex;
    /* font-size: clamp(8px, 3.0vw, 12px);    */
    /* font-weight: 500; */
    /* color: rgba(0, 0, 0, 1); */
    width: 80%;
    flex-wrap: nowrap;
    align-items: center;
    gap : 10px;
}

.buttonFix{

    display: flex;
    color : var(--primary-color);
    justify-self: flex-end;
}


.variantsInfo{
    display: flex;
    flex-direction: row;
    gap : 10px;
    
}


.variantText{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-self: flex-start;
}

p{
    display: flex;
    font-size: clamp(8px, 3.0vw, 12px);   
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    
    align-items: center;
    padding-left: 15px;
    line-height: 14px;
    letter-spacing: 0.05em; 
}

h2{
    font-size: clamp(10px, 3.2vw, 14px);
}


.addButton {
    position: fixed;
    bottom: 70px;
    /* left: 0;     */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 90%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: white;

    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;

    cursor: pointer;

}

.addButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}



.search {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    padding-left: 10px;
    font-size: clamp(8px, 3.4vw, 14px);


}

.search::placeholder {
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 3.4vw, 14px);
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-top: 15px;
    color: rgba(181, 181, 181, 1);
}

.cthrow {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-color);
    font-size: clamp(8px, 3vw, 14px);
}


.brandList {
    max-height: 54vb; /* You can adjust this value */
    overflow-y: auto;

    /* max-height: calc(100% - 50px);  */
}

.categoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    font-size: clamp(8px, 3vw, 14px);
    padding-right: 15px;

    padding-left: 10px;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
}
