
.newProduct,
.someOne {
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 1px;
    line-height: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 4px 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
}

.someOne {
    background: #7FBE40;
    z-index: 2;
}

.newProduct {
    background: linear-gradient(180deg, #EC5555 0%, #C343C6 100%);
    z-index: 4;
}

.title {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
}


@media (max-width: 370px) {
    .someOne,
    .title {
        font-weight: 500;
        font-size: 7px;
    }
}
