.wrapper {
    flex-grow: 1;
    position: relative;
    /*border: 1px solid red;*/
    z-index: 19;
    margin-top: 2vh;
}

.wrapper img {
    position: absolute;
    right: 25px;
    top: 35px
}

.label {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 0 14px;
}

.title {
    width: 100%;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background-color: #EDEDED;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin-top: 5px;
    min-height: 32px;
    cursor: pointer;
}