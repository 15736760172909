.wrapper{
    padding: 10px 10px;
    /* padding-left: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    
    margin-bottom: 110px;
 
}

.mainItemContainer{
    color :rgba(96, 96, 96, 1) ;
    margin-bottom: 10px;
}

.contentContainer{
    display: flex;
    flex-direction: column;
}

.lotContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.itemContainer{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
}



.itemImage{
    margin-right: 15px; 
    }
.itemImage img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    /* margin-right: 15px; */
}


.itemInfo{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    font-family: 'Rubik', sans-serif;
}

.itemArticle{
font-size: clamp(6px, 2.8vw, 8px);

}

.itemName{
font-size: clamp(8px, 3.4vw, 12px);
}


.itemPurposeCount{
font-size: clamp(5px, 2.6vw, 7px);
}


.labelContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 25px;
    gap:10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}


.labelLot{
    font-family: Rubik;
    font-size: 12px;
    font-weight: 600;
    line-height: clamp(8px, 3.4vw, 12px);
    letter-spacing: 0.05em;
    text-align: center;
    color: rgba(96, 96, 96, 1);

}


.lotFields{
    display: flex;
    flex-direction: row;
    
    gap: 5px;
    align-items: center;
    flex-wrap: nowrap;

}


.lotFields.disabled {
    background-color: #f0f0f0; /* or some gray color */
    /* other styles to make it look disabled */
  }
  
  .doButton.disabled {
    background-color: #ccc;
    color :rgb(138, 130, 130)

    /* other styles to make it look disabled */
  }
  
  .inputPrice:disabled {
    background-color: rgba(239, 239, 239, 1);

    color :rgb(138, 130, 130)
  }

  .bookingItems {
    /* margin-left: 25px; */
    /* border-radius: 1px  solid red;    */
    width: 30%;
    display: flex;  
    align-items: center; /* Corrected property */
    justify-content: center;
    text-align: center;
}

.bookingItemsDisabled{
    /* margin-left: 25px; */
    /* border-radius: 1px  solid red;    */
    width: 30%;
    display: flex;  
    align-items: center; /* Corrected property */
    justify-content: center;
    text-align: center;
    color :rgb(138, 130, 130)
}

.lotsButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    /* justify-self: center; */
    padding-left: 30px;
    padding-right: 10px;
    /* gap: 15px; */
    /* border: 1px solid red; */
}

.labelIcon{
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
    align-items: center;
    /* align-self: flex-start; */
    flex-wrap: nowrap;
    gap : 2px;
}


.buttonContainer{
    display: flex;
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



.infoIcon{
    width: 15px;
    height: 15px;
   

    
}


.inputPrice{
    background: rgba(239, 239, 239, 1);
    border : none ;
    width: 110px;
    height: 30px;
    border-radius: 5px;
    font-family: Rubik;
    font-size: clamp(10px, 3.4vw, 14px);
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: center;
    color:  black;


}

 ::placeholder{
    color: rgba(181, 181, 181, 1);

}


.addLot{
    background: none;
    border: none;

    font-family: Rubik;
    font-size: clamp(10px, 3.4vw, 12px);
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    color : var(--primary-color);

}

.addLot:disabled{
    color: rgba(181, 181, 181, 1);
    cursor: not-allowed;

}


.buttonText {
    border: none;
    width: 35px;
    background: none;    
    /* text-align: center; */
    align-items: flex-start;
    justify-content: flex-start;
    outline: none; 
    /* width: 2em;  */
    text-align: center;
    flex: 1;
  }
  
  .buttonText:disabled {
    /* background: rgba(239, 239, 239, 1); */
    color :rgb(138, 130, 130)
  }


  .buttonText:focus {
    border-bottom: 1px solid #000;
  }
  
  /* Hide arrows */
  .buttonText[type='number'] {
    appearance: textfield;
  }
  
  .buttonText[type='number']::-webkit-inner-spin-button,
  .buttonText[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }




.buttonFixed{
    position: fixed;
    bottom: 70px;
    left: 2.5%;  /* Add this property */
    width: 95%;
    height: 35px;
    background: rgba(79, 140, 231, 1);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    z-index: 999;
}
.buttonFixed:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
    }
    
.wraperVariat{
        margin-bottom: 10px;
        padding-left: 10px;
        /* padding-right: 10px; */
        gap: 10px;
        /* display: flex; */
        /* flex-direction: row; */
       
    }

.infoVariant{
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        gap: 10px;
    }
    
.row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* margin-top: 5px; */
        margin-bottom: 5px;

    }
.variantsInfo{
        display: flex;
        flex-direction: column;
        /* gap : 10px; */
        color: rgba(96, 96, 96, 1);

        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
        font-size: clamp(6px, 3.0vw, 10px);
        letter-spacing: 0.05em;
        text-align: left;

    }

    .variantText{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: flex-start;
        gap:10px;
        width: 100%;
    }

.labelInfo{
        font-size: clamp(6px, 2.9vw, 10px);
    }Закры
.textInfo{
        font-size: clamp(6px, 2.9vw, 10px);
        font-weight: 500;
    }



.character{
        background: rgba(237, 237, 237, 1); 
        width: 100%;
        height: 40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        line-height: 12px;  
        font-family: 'Rubik', sans-serif;
    }
    
    .character{
        flex-basis: calc(50% - 10px); /* Assuming a gap of 10px */
        box-sizing: border-box;
    }
    
    .character:last-child:nth-child(odd) {
        flex-basis: 100%;
      }
    
      
    
.characterRow{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
    }




.formLots{
    display: flex;
    flex-direction: column;
    gap : 5px;
}


.doButton {
    display: flex;
    flex-direction: row;
    /* height: 25px; */
    /* width: 30%; */
    /* justify-content: space-around; */
    align-items: center;  
    border-radius: 50px;
    border: none;
    background: rgba(217, 217, 217, 1);
    /* color: rgba(181, 181, 181, 1); */
    font-size: clamp(12px, 3.4vw, 16px);
    height: 15px;
    width: 15px;
}
  

.countButton{
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: flex-end;
    /* gap: 5px; */
    align-self: flex-end;
    align-items: center;
}

.countButtonDisabled{
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: flex-end;
    /* gap: 5px; */
    align-self: flex-end;
    align-items: center;
    color :rgb(138, 130, 130)
}



.rightContainer{
    display: flex;
    align-items: self-start;
    /* align-items: center; */
    width: 30%;

}


.link{
    
    font-weight: 501;
    color: var(--primary-color);
    font-size: clamp(8px, 3.0vw, 12px); 
}