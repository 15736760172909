

.ShopContainer {
  /* border: 1px solid red;   */
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */
  gap : 15px;
  width: 100%;

}

.row {

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: 30px;
}

.title {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: clamp(16px, 3.6vw, 18px);
}

.likeContainer {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding-right: 10px;
  gap : 15px
}


.logoContainer {
  width: 100px;  
  height: 100px;
  display: flex;
  align-self: center;
  justify-content: center;  border-radius: 50px;

  

  /* background: url(70a72c4dc7722c192638ec6af340a3be); */
}

.logo {
	width: 100px; 
      height: 100px;
      object-fit: cover;
      
}

.nameContainer {

  display: flex;
  justify-content: center;

  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: clamp(22px, 4.2vw, 24px);
  line-height: 18px;

  text-align: center;
}

.name {
  /* white-space: nowrap; */
}


.approveLabel {
  /* width: 19px; */
  height: 19px;
}

.allInformation {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: rgb(255, 255, 255);
  height: 63px;
}

.allInformation::-webkit-scrollbar {
  width: none;
}

.ShopSlide {
  min-width: 140px;
  height: 60px;
  background: #EDEDED;
  border-radius: 10px;
  font-weight: 700;
  font-size: clamp(12px, 2.2vw, 14px);
  line-height: 22px;
  letter-spacing: 0.05em;
  /* gap: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: auto !important; */
  margin-right: 13px;
}


.text{
  font-weight: 400;
}
