.closeLineContainer {
    padding: 10px 0 13px;
}

.closeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    gap: 15px;
}

.closeLine {
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: #606060;
    margin: 0 auto;
    cursor: pointer;
}


.closeText {
    color: var(--dark-background-color);
    font-weight: 400;
    font-size: 8px;
    line-height: 14px;
    cursor: pointer;
}

.modalContent {
    padding: 20px;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    max-width: 428px;
    margin: 0 auto;
}

.modal {
    height: auto;
    max-height: 80%;
    overflow-y: scroll;
    width: 100vw;
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
    transform: translateY(200%);
    transition: transform 0.3s ease-out;
    
}

.modalWithOverlay {
    height: auto;
    /*max-height: 80%;*/
    overflow-y: scroll;
    width: 90%;
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;

    z-index: 10;
    transform: translateY(200%);
    transition: transform 0.3s ease-out;
}

.active {
    transform: translateY(-10%);
    transition: transform 0.3s ease-out;
}


.checkmark {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-bottom: solid 3px #606060;
    border-right: solid 3px #606060;
    position: relative;
    right: -42%;
    transform: rotate(45deg) translateY(-12px);
}

.crossLine {
    width: 20px;
    height: 3px;
    position: relative;
    cursor: pointer;
}

.crossLine:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    cursor: pointer;
}

.crossLine:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
    cursor: pointer;
}

.overlay {
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 15;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}