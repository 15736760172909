
.content {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    

}
.row {
    display: flex;
    /* justify-content: space-between; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    gap :10px;
    /* margin-top: -5px; */

}
.rowTitle {

    color: black;
}
.chooseRow{
    display: flex;
    flex-direction: row;
    gap :  10px;
    font-family: 'Rubic', sans-serif;
    font-size: clamp(12px,  3.2vw, 14px); 
    line-height: 24px;
    font-weight: 400;
    flex-wrap: wrap;
    padding-left: 10px;
    

}

.chooseRow{
    display: flex;
    flex-direction: row;
    gap :  10px;
    font-family: 'Rubic', sans-serif;
    font-size: clamp(12px,  3.2vw, 14px); 
    line-height: 24px;
    font-weight: 400;
    flex-wrap: wrap;
    padding-left: 10px;
    

}

.modifiersSelected{
    border: 3px solid var(--primary-color);

    background-color: var(--light-background-color);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;
    

}

.modifiers{
    /* border: 3px solid  yellow; */
    background-color: var(--light-background-color);
    border: 3px solid var(--light-background-color);

    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;

}

.modifiersUnavailable{
    border: 3px solid var(--light-background-color);
    background-color: rgb(223, 221, 221);
    color : var(--light-background-color);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;

}

