.closeLineContainer {
    padding: 10px 0 0;
}

.closeLine {
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: #606060;
    margin: 0 auto;
}

.checkmark {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-bottom: solid 3px #606060;
    border-right: solid 3px #606060;
    position: relative;
    right: -42%;
    transform: rotate(45deg) translateY(-12px);
}

/* .closeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    gap: 15px;
} */




/* .closeText {
    color: var(--dark-background-color);
    font-weight: 400;
    font-size: 8px;
    line-height: 14px;
} */

.content {
    /* border-radius: 10px; */
    /* background: #FFF; */
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -15px;
    /* row-gap: 20px; */
    /* font-weight: 500; */
    /* font-size: 14px; */
    /* line-height: 20px; */
    /* max-width: 428px; */
    /* max-width: clamp(260px, 80vw, 328px); */

}

@media screen and (max-width: 365px) {
    .modalContent {
        padding: 0 5px;
    }

}

.modal {
    margin-bottom: 60px;
    height: auto;
    max-height: 90%;
    overflow-y: scroll;
    width: 100%; 
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    left :0;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
    z-index: 12;
}

/* .modalWithOverlay {
    height: auto;
    /*max-height: 80%;*/
    /* overflow-y: scroll;
    width: 90%;
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;

    z-index: 10;
    transform: translateY(200%);
    

    transition: transform 0.3s ease-out; */
/* }  */

/* .active {
    transform: translateY(0);
    transition: transform 0.3s ease-out;
} */




/* .crossLine {
    width: 20px;
    height: 3px;
    position: relative;
}

.crossLine:before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
}

.crossLine:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    position: absolute;
    right: 0;
    transform: rotate(-45deg);
}

.overlay {
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 15;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.question {
    font-family: 'Rubic', sans-serif;
    font-weight: 400;
    font-size: clamp(8px, 2.8vw, 10px);
    line-height: 24px;
    /* color: var(--shared-color); */
    color: var(--dark-background-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    padding-right: 20px;
    /* padding: 0 20px 10px; */
}



.row {
    display: flex;
    /* justify-content: space-between; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    gap :10px;
    /* margin-top: -5px; */

}

.wrapper {
    width: 100%
}

.rowTitle {

    color: black;
}

.rowContent {
    color: var(--grey-text-color);
}

.link{
    font-size: clamp(10px, 3.0vw, 12px);
    color: var(--primary-color);
}

.chooseRow{
    display: flex;
    flex-direction: row;
    gap :  10px;
    font-family: 'Rubic', sans-serif;
    font-size: clamp(12px,  3.2vw, 14px); 
    line-height: 24px;
    font-weight: 400;
    flex-wrap: wrap;
    padding-left: 10px;
    

}

.modifiersSelected{
    border: 3px solid var(--primary-color);

    background-color: var(--light-background-color);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;
    

}

.modifiers{
    /* border: 3px solid  yellow; */
    background-color: var(--light-background-color);
    border: 3px solid var(--light-background-color);

    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;

}

.modifiersUnavailable{
    border: 3px solid var(--light-background-color);
    background-color: rgb(223, 221, 221);
    color : var(--light-background-color);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-self: center;

}



.lotContent{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    gap : 5px;
    /* max-width: clamp(360px, 120vw, 428px); */
}


.text{
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Rubic', sans-serif;
    font-weight: 600;
    font-size: clamp(16px, 3.6vw, 18px);
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    /* padding-right: 20px; */
    /* padding: 0 20px 10px; */
}

.priceContainer {
    font-family: 'Rubik', sans-serif;
    /* flex-wrap: nowrap; */
    white-space: nowrap;

    width: 70%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;
    /* flex-grow: 1; */

}
.cost {
    font-weight: 400;
    font-size: clamp(16px, 3.6vw, 18px);
    text-align: left;
    padding-bottom: 2px;
}

.minStep {
    font-size: clamp(10px, 3.0vw, 12px);
    color: var(--dark-background-color);
    text-align: left;
}

.totalPrice {
    font-size: clamp(16px, 3.6vw, 18px);
    font-weight: 700;
    margin-top: 8px;
    text-align: left;
}

.maxPrice{
    padding-left: 7px;
    min-width: 10%;
    color: var(--dark-background-color);
    font-size: clamp(12px, 3.2vw, 14px);
    text-align: left;
    text-decoration: line-through;

}
.bonusContainer{

    font-size: clamp(12px, 3.2vw, 14px);
    display: flex;
    letter-spacing: 0.001em;    
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap : 7px;
    /* padding-right: 10px; */
    font-weight: 500;
    line-height: 24px;

}

.bonusText{
    margin-left: -5px;
    text-align: center;

}
.benefitText{
    text-align: center;
    color: var(--primary-color);
    

}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.BasketToCardButton{
    width: 80%;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: 'Rubic', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(14px, 3.4vw, 16px);
    line-height: 25px;
    letter-spacing: 0.075em;
    padding: 7px;
    color: white;
    display: flex;
    justify-content: center;
}
.BasketToCardButton:disabled{
    background-color: rgba(189, 189, 189, 0.6);
    cursor: default;
}




.totalContainer{
    display: flex;
    flex-direction: row;
    letter-spacing: 0.001em;    
    align-items: space-between;
}



.changeWraper{
    /* border: 2px solid black; */

    display: flex;
    flex-direction: column;
    /* max-height: 80px; */
    /* justify-content: flex-end; */
    align-items: center;

    /* row-gap: 4px; */
    /* min-width: 170px; */
    /* max-width: clamp(100px, 30vw, 228px); */
    /* width: 30%; */

}

.changeContainer {
    /* width: 90px; */
    font-size: 18px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 10px;
}

.increase, .decrease {
    display: flex;
    /* display: inline-block; */
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #D9D9D9;
    font-size: inherit;
    position: relative;
}


.amount {
    /* width: 10px; */
    font-size: clamp(14px, 3.4vw, 16px);
    /* color: var(--dark-background-color); */
    font-weight: 500;
    line-height: 24px;
}

.dateContainer {
    margin-top: 4px;
}

.timeContent {
    white-space: nowrap;
    font-size: clamp(6px, 2.6vw, 8px);
    font-family: 'Rubic', sans-serif;
    font-weight: 400;
    margin-left: -10px;
}

.jointInfo {
    font-size: clamp(6px, 2.6vw, 8px);
    /* line-height: 100%; */
    font-family: 'Rubic', sans-serif;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: -10px; */
    margin-left: -10px;
}

.highlighting {
    color: var(--primary-color)
}



.notification {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    background-color: #4CAF50;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }