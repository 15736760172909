
.shareContainer {
    position: absolute;
    left: 5%;
    top: 0;
}

.avatarContainer {
    width: 100px;
    height: 100px;
    background: var(--primary-color);
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.noPhoto {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 22px;
}

.header {
    /*background: linear-gradient(180deg, rgba(236, 85, 85, 0.5) 0%, rgba(195, 67, 198, 0.5) 100%);*/
    padding: 14px 0;
    /*border: 1px solid red;*/
    font-family: inherit;
    position: relative;
}

.allInformation {
    display: flex;
    gap: 13px;
    overflow: scroll;
    scrollbar-width: none;
    scrollbar-color: rgb(255, 255, 255);
    margin-left: 15px;
}

.allInformation::-webkit-scrollbar {
    width: none;
}

.profileSlide {
    font-family: inherit;
    font-weight: 700;
    width: 30%;
}
.profileSlideHide{
    background-color: var(--dark-background-color);
}

.headerText {
    font-size: 12px;
    line-height: 22px;
}

.headerInfo, .headerInfoHide {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: #FFFFFF;
    background-color: var(--primary-color);

    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.headerInfoHide{
    background-color: var(--dark-background-color);
}



.name {
    padding: 17px 25px 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 18px;
    font-family: inherit;
}

.linkText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
    padding-bottom: 11px;
}