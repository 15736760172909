.increase, .decrease {
    display: inline-block;
    /* display: flex; */
    text-align: center; 
    align-self: center; 
    justify-content: center;
    align-items: center;

    /* align-items: center;
     */
    /* align-text: center; */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #D9D9D9;

    /* margin-left: 5px; */
    /* font-size: inherit; */
    /* position: relative; */
}



.container {
    /* border: 1px solid red; */
    font-weight: 400;
    max-width: 35%;
    font-size: clamp(16px, 3.6vw, 18px);
    display: flex;
    padding-right: -10px;
    padding-left: -10px;
    
}

.amount {
    cursor: text; /* Changes the cursor style */
}

.amount.editing, .amount:hover {
    /* Styles to apply when the amount is being edited or hovered */
    background-color: #f0f0f0; /* Example: light background */
    outline: 1px solid #ccc; /* Example: subtle outline */
}

.hiddenInput {
    max-width: 40px;
    background-color: white;
    border-radius: none;
    border: none;
    outline: none; 
    -moz-appearance: textfield;
    text-align: center; 
    align-self: center; 
}
