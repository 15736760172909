.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    /* flex-wrap: nowrap; */
    
    /* border : 2px solid red; */
    /* height: inherit; */
    /* max-height: 60px; */
}


@media (max-hight: 60px) {
    .container {
        width: 90%; 
        /* max-height: 60px; */
        row-gap: 5px;
    }
}