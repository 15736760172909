
.modal {
    margin-bottom: 60px;
    height: auto;
    max-height: 70%;
    overflow-y: scroll;
    width: 100%; 
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    left :0;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
    z-index: 12;
    padding: 15px;
    font-family: 'Rubik', sans-serif;

    
}

.closeLineContainer {
    padding: 5px 0 13px;
    margin-bottom: 25px;
}

.checkmark {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-bottom: solid 3px #606060;
    border-right: solid 3px #606060;
    position: relative;
    right: -42%;
    transform: rotate(45deg) translateY(-12px);
}

.closeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    gap: 15px;
}

.closeLine {
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: #606060;
    margin: 0 auto;
    cursor: pointer;
}


.headerContainer{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 25px;

}


.filterTitle {
    /* display: flex; */
    flex-grow: 1;  /* Allows the title to take up any extra space */
    text-align: center; 
    /* justify-content: center; */
    padding-left: 60px;
    /* width: 60%; */
    font-size: clamp(10px, 12px, 14xp);
    /* padding-bottom: 34px; */
    font-weight: 600;
    /* font-size: 16px; */

}
.filterDropAll{
    display: flex;
    justify-content: flex-end;
    font-size: clamp(8px, 10px, 12px);
    font-weight: 400;
    color: var(--primary-color);
    cursor: pointer;
    /* margin-left: 10px; */
}





.filterTitle {
    /* display: flex; */
    flex-grow: 1;  /* Allows the title to take up any extra space */
    text-align: center; 
    /* justify-content: center; */
    padding-left: 60px;
    /* width: 60%; */
    font-size: clamp(10px, 12px, 14xp);
    /* padding-bottom: 34px; */
    font-weight: 600;
    /* font-size: 16px; */

}
.filterDropAll{
    display: flex;
    justify-content: flex-end;
    font-size: clamp(8px, 10px, 12px);
    font-weight: 400;
    color: var(--primary-color);
    cursor: pointer;
    /* margin-left: 10px; */
}


.filterChoice {
    display: flex;
    align-items: center;
    justify-content: center ;
    width: 20px;
    height: 20px;
    border: 2px solid #606060;
    border-radius: 50%;
    margin-right: 6px;
}

.active {
    border: 4px solid transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--primary-color) 0%, var(--primary-color) 100%) border-box;
    /*background: var(--primary-color);*/
}

.sortText {
    display: flex;
    justify-self: center;
    align-self: center;
}



.line {
    width: 30px;
    height: 2px;
    background-color: #606060;
    margin: 0 15px;
}

.filterContainer ul{
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Rubik', sans-serif;
    margin-bottom:20px;
}


.container {
    /* margin-top: 30px; */
    padding: 10px;
}

.sortList {
    font-weight: 600;
    font-size: clamp(9px, 11px, 13px);
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-top: 1em;
    padding-bottom: 1.2em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 41px;
}



.sortList:first-child {
    border-top: solid 1px #606060;
}


.applyButton {
    width: 100%;
    /*background: linear-gradient(*/
    /*        90.62deg,*/
    /*        rgba(240, 6, 6, 0.6) 0%,*/
    /*        rgba(6, 40, 241, 0.6) 49.48%,*/
    /*        rgba(239, 114, 242, 0.6) 100%*/
    /*);*/
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: "Rubic", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    /* padding: 14px; */
    /* margin-top: 88px; */
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
}




.sortListPrice {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-bottom: 1.2em;
    text-align: left;
    gap : 15px;
    /* border: 1px solid #606060; */
}


.priceRangeContainer{
    display: flex;
    align-items: center;
    gap: 20px
}

.priceRange{
    color: var(--light-background-color);
    width: 30px;
    border-bottom: 2px solid var(--light-background-color);
}


.priceInput{
    width: 100px;
    height: 39px;
    border-radius: 10px;
    background: var(--light-background-color);
    border : none;
    padding-left: 5px;
}
.priceInput:focus{
    outline: none;
    border: solid 1px --primary-color;
}
.priceInput::placeholder{
    color: #606060;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.counterContainer{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.counter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 38px;
    height: 28px;
    border-radius: 10px;
    background: var(--primary-color);
    color: white;
}