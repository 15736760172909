.wrapper {	
	margin-top  : 5px;
	padding-left: 14px;
	font-size   : 14px;
}

.title {
	/* width: 399px; */
	/* height: 35px; */
	/* left: 14px; */
	/* top: calc(50% - 35px/2 - 336.5px); */
	/* margin-bottom: 10px; */

	font-family: 'PT Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;

	/* or 120% */
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.05em;

	color: #000000;
}

.titleContainer {
	display        : flex;
	justify-content: center;
	align-items    : center;
	column-gap     : 10px;

}

.starCount {
	color      : #606060;
	/* margin-top: 10px; */
	font-weight: 600;
	font-size  : 20px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.05em;


}

.star {
	margin-top: 10px;
	width : auto;
	height: 20px;
}

.ratingContent {

	font-family: 'PT Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;

	margin-bottom: 10px;
	/* or 171% */
	/* display: flex; */
	/* align-items: center; */
	/* text-align: center; */
	letter-spacing: 0.05em;

	color: #000000;


	line-height: 24px;
}