
.PriceContainer {
    display: flex;
    align-items: flex-end;
    column-gap: 10px;
}

.jointPrice {
    font-size: 1.4em;
    font-weight: 600;
    font-family: var(--main-font);
}

.ordinaryPrice {
    font-size: 1em;
    font-weight: 400;
    font-family: "Exo 2", sans-serif;
    text-decoration: line-through;
}

.Price {
    font-size: clamp(12px, 3.4vw, 14px);
    font-weight: 400;
    font-family: var(--secondary-font);
    text-align: left;
    /* line-height: 1px; */
}

.item {
    position: relative;
    width: 100%;
    row-gap: 2%;
    column-gap: 3%;


}

.itemInnerContainer {
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.slide {
    position: relative;
}

.newProduct,
.someOne {
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    letter-spacing: 1px;
    line-height: 12px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;


}

.someOne {
    background: rgb(59, 201, 210);
    padding: 4px 0 24px;
    border-radius: 10px 10px 0 0;
    z-index: 2;
}

.newProduct {
    background: rgb(255, 72, 72);
    padding: 4px 10px;
    border-radius: 10px 10px 0 0;
    z-index: 4;
    display: flex;
    justify-content: space-between;
}

.promoTitle {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
}

.promoPrice {
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
}


@media (max-width: 370px) {
    .someOne,
    .promoTitle {
        font-weight: 500;
        font-size: 7px;
    }

    .promoPrice {
        font-weight: 600;
        font-size: 10px;
    }
}

.ProductContent {
    padding: 6px 7px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.about {
    font-size: clamp(8px, 3.0vw, 10px);
    font-weight: 400;
    font-family: var(--secondary-font);
    line-height: 12px; 
    margin-top: 2px;
    margin-bottom: 3px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: calc(100% - 5px);
    flex-shrink: 0; 
}


.subCategoryContent , .shopNameContent {
    font-weight: 400;
    font-size: clamp(8px, 2.9vw, 9px);
    color: var(--dark-background-color);
    /* min-height: 18px; */
    line-height: 12px;
    display: flex;
    
}


.shopNameContent {
    display: flex;
    flex-wrap: nowrap; 
    max-width: 70%;  
    gap: 5px;

}

.shopNameContent span {
    flex-shrink: 0; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: calc(100% - 15px); 
}

.approveImage {
    width: 10px;
    height: 10px;
    flex-shrink: 1; 
}


.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* align items to the left */
    margin-left: -15px;
    width: 70%;
}

.ratingContainer svg {
    /* additional styles if necessary */
}


.starCount {
    margin-left: -3px;
}

@media screen and (max-width: 365px) {
    .starCount {
        margin-left: 0;
    }
}















