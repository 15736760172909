.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: auto;
    z-index: 19;
    /*box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);*/
    box-shadow: 0 -1px 5px -1px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    transform: translateY(0);
    /*border: 2px solid;*/
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    max-width: 428px;
    margin: 0 auto;
}

.count {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    background-color: #ef2222;
    position: absolute;
    top: -5px;
    right: -1px;
}

.container {
    position: relative;
}

.img {
    /*stroke: url(#paint0_linear_37_410);*/
    stroke: black;
    color: black;
    fill: black;
}

.imgDisable{
    stroke: #B5B5B5;
    fill: #B5B5B5;

}


.activeImg {
    /*stroke: url(#paint0_linear_37_410);*/
    stroke: var(--primary-color);
    fill: var(--primary-color);
}

.linkContainer {
    display: flex;
    align-items: flex-end;
}
