.wrapper {
	padding: 20px 0 75px;
}

.filterWrapper {
	display        : flex;
	column-gap     : 9px;
	overflow       : scroll;
	scrollbar-width: none;
}

.filterWrapper::-webkit-scrollbar {
	width: none;
}

.filter {
	display      : inline-block;
	background   : #EDEDED;
	min-width    : 120px;
	border-radius: 14px;
	font-weight  : 400;
	font-size    : 12px;
	line-height  : 24px;
}