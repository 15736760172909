.header {
	display: flex;
	justify-content: center;
	align-items: center;

	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: clamp(16px, 3.6vw, 20px);
	color: #000000;
}

.title {
	

	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: clamp(12px, 3.0vw, 14px);
	line-height: 16px;

	
	letter-spacing: 0.05em;

	color: #000000;

}



.react_tabs_tab {
	/* width: 172px; */
	/* height: 0px; */
	/* left: 22px; */
	/* top: 184px; */

	/* border: 2px solid #4F8CE7; */
	width: 50% !important;
}

.container {
	/* margin-bottom: 0%; */
	padding-left: 10px;
	padding-right: 10px;
	/* margin-top: 100px; */
	/* margin: 70px 20px; */
}

/* .watch{
	
	margin-top: 40px;
	margin-bottom: 20px;

	font-family: 'PT Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 12px;

	or 75%
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;

	color: #000000;


} */
.line {
	display: flex;
	width: 428px;
	height: 15px;
	background: rgba(96, 96, 96, 0.1);

}
