.header {
	margin-top: 20px;
    margin-bottom: 20px;

    left: 22px;
    top: calc(50% - 42px/2 - 77px);
    
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;


	color: #000000;
}
