.noPhoto {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 22px;
    color: #909090;
}

.wrapper {
    width: 100px;
    height: 100px;
    /*max-width: 100px;*/
    /*max-height: 150px;*/
    background: #e3e3e3;
    border-radius: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}