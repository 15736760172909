/* Arrows */
.slick-prev,
.slick-next {
    display: none !important;

}


/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    top: 5px !important;
    bottom: auto !important;
    left: 5px !important;
    display: flex !important;
    width: auto !important;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 3px !important;
    position: absolute !important;
}

.slick-dots li {
    width: 5px !important;
    height: 5px !important;
}

.slick-dots li button {
    width: 5px !important;
    height: 5px !important;
    padding: 0 !important;
}

.slick-dots li button:before {
    font-size: 5px !important;
    line-height: 5px !important;

    width: 5px !important;
    height: 5px !important;
}

.slick-dots li.slick-active button:before {
    opacity: .5 !important;
}

