.root{
    display: flex;
    flex-direction: column;
    /*height: 90vh;*/
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    font-family: 'Rubic', sans-serif;
    margin-bottom: 80px;
    margin-top: 10px;
}

.pictureContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: #fff;
    justify-content: center;
}

.shareButton{
    display: flex;
    justify-content: flex-start;
    width: 100%; 
    height: 30px;
    padding-left: 10px;
}

.picture{
    /* padding-top: 20px; */
    width: 100px; 
    height: 100px; 
    display: flex;
    justify-self: center;

    /* border: 3px solid rgba(31, 26, 26, 0.1); */
}
.picture img {
    object-fit: cover;
    width: 100%; 
    height: 100%; 
    margin-bottom:10px;
}

.name{

    font-size: clamp(8px, 4.3vw, 18px);
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    margin-top: 20px;

    margin-bottom: 10px;
}
.settings{
    font-size: clamp(6px, 3.2vw, 12px);
    font-weight: 500;
    color: rgba(79, 140, 231, 1);

}
.settingsHide{
    font-size: clamp(6px, 3.2vw, 12px);
    font-weight: 500;
    color: rgba(181, 181, 181, 1);
}



.mainInfoContainer{
    padding-top: 15px;
    background-color: #fff;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.infoText{
    padding-left: 10px;
    width: 80%;
    font-size: clamp(12px, 4.5vw, 20px);
    font-weight: normal;
    flex-direction: column;
    display: flex;
    text-align: left;
    letter-spacing: 0.05em;

}
.sumInfo{
    margin-left: 15px;
    padding-left: 15px;
}
.arrow{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.moveConatainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color: #fff;
    margin-top: 10px;
    align-items: center;
    padding-left: 10px;
}

.move{
    font-size: clamp(6px, 3.2vw, 12px);
    font-weight: bold;
}

.optionConatainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-top: 10px;
    padding-left: 10px;
}




.label{
    padding-top: 15px;
    display: flex;
    font-size: clamp(10px, 3.6vw, 14px);
    font-weight: bold;
    margin-bottom: 10px;
    justify-self: flex-start;
}

.option{
    display: flex;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: normal;
    justify-content: space-between;
    border-radius: 10px;
    height: 40px;
    padding-left: 10px;
    padding-right: 5px;
    background: rgb(248, 248, 248);
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    /* border: 1px solid rgba(31, 26, 26, 0.1); */
}

.optionLabel, .optionLabelHide{
    display: flex;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: 501;
    /* border: 1px solid rgba(31, 26, 26, 0.1); */
}

.optionLabelHide{
    color: var(--dark-background-color);
}



.line{
    height: 1px;
    background:  rgba(7, 1, 1, 0.1);
    margin-left: 40px;
    margin-right: 20px;
}

.indicators{
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    height: 40px;
    padding-left: 10px;
    padding-right: 5px;
    background: rgb(248, 248, 248);
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    /* border: 1px solid rgba(31, 26, 26, 0.1); */
}

.indicatorsLabel{
    width: 75%;
    display: flex;
    align-items: left;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: 501;

}
.indicatorsValue{
    width: 23%;
    height: 25px;
    border-radius: 5px;
    padding: 10, 5, 10, 5;    
    background: rgba(239, 239, 239, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: 501;
}

.infoImage{
    width: 5%;
    display: flex;

    justify-self: flex-end;
    align-items: center;
    margin-left: 10px;
}