.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    position: relative;
    width: 100%;
    /* background: rgba(96, 96, 96, 0.1); */
    gap : 10px;
    font-family: 'Rubic, sans-serif';
    /* padding-left: 10px; */
    overflow-y: scroll;
    margin-bottom: 80px;
}

.title {
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: clamp(15px, 5vw, 20px);
    line-height: 1.2em;
    text-align: center;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    background-color: white;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    /* padding-top: 10px; */
    /* padding-left: 13px; */
    /* padding-right: 13px; */

}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 10px 10px 10px;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family :'Rubik', sans-serif;

    /* justify-content: space-between; */
    /* background-color: white; */
    /* padding: 10px 10px 10px; */
}

.text{

    font-weight: 600;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
    margin-bottom: 7px;
}

.linkText{
    color :rgba(79, 140, 231, 1);

    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 10px);
}

.link{
    color :rgba(79, 140, 231, 1);
    align-self: center;
    height: 13px;

}

/* .buttonContainer{
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: flex-start;
    padding-top:10px ;
    margin-bottom: 10px;
    gap: 5px;
} */
.dealButton{
    width : 70px;
    height: 29px;
    border-radius: 10px;
    border: rgba(181, 181, 181, 1) 2px solid;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: clamp(4px, 2.8vw, 12px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 10px; */

}
/* .active {
    border: 2px solid var(--primary-color);
} */

.itemImage{
    /* margin-right: 15px;  */
    width: 80px;
    gap : 7px;
    display: flex;
    align-items : center;
    flex-direction: column;
    /* border : 1px solid red; */
    /* white-space: nowrap; */
    
    }


.itemImage img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px;
        /* margin-right: 15px; */
    }

.orderContainer{
    background: white;
    padding-left: 10px;
    padding-top: 10px;
    gap : 10px;
    display: flex;
    flex-direction: column; 
}

.imageContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    flex-wrap: wrap;
    
    /* padding-left: 10px; */
}

.orderInfo{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 600;
    font-size: clamp(8px, 2.8vw, 10px);
    line-height: 1.2em;
}

.itemStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    border:  1px solid;
    border-radius: 5px;
    width: 60px;
    height: 20px;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 600;
    font-size: clamp(6px, 2.6vw, 8px);
    line-height: 1.2em;
    box-shadow: 0 0 0 1px ;

    
    color: rgba(96, 96, 96, 1);

}

.buttonContainer{
    display: flex;
    flex-direction: column;
    /* gap : 10px; */
    /* border: 1px solid red; */
    width: 80%;
    gap : 5px;
    /* padding: 10px 10px 10px; */
}


.buttonGetting, .buttonDecline{

    width : 100%;
    /* height: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(4px, 2.8vw, 8px); 
    border: none;
    background: white;

    /* background-color: white; */

    
}
.buttonGetting:disabled{
    /* background: rgba(181, 181, 181, 1) ; */
    width: 75%;

}

.buttonSave{
    width : 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: clamp(4px, 2.8vw, 8px); 
    border: none;

}



.quanityContainer{
    display: flex;
    /* width: 65px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 400;
    font-size: clamp(4px, 2.6vw, 8px);

    /* line-height: 1.2em; */

}

.orderQuantity{
    /* padding-left: -5px; */
    font-size: clamp(4px, 2.4vw, 8px);
    word-spacing: -2px;
}

.orderSum{
    font-weight: 600;
}

.chooseForm{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: white; */
    align-items: flex-start;
    padding: 10px 10px 10px;
    font-family: 'Rubic, sans-serif';

}


.label{

    display: flex;
    align-self: center;
    font-weight: 600;
    font-size: clamp(12px, 3.4vw, 16px);
    margin-bottom: 15px;

}

.inputField{
    width: 100%;
    height: 25px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    font-family: 'Rubic, sans-serif';
    border : none;  
    font-weight: 400;

    font-size: clamp(4px, 2.2vw, 8px);
    padding-left: 5px;
    margin-bottom: 5px;
}

.inputField::placeholder{
    color: rgba(96, 96, 96, 0.5);

}

.inputField:focus{
    outline: none;
}   

.typeContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* padding: 10px 10px 10px; */
    font-weight: 600;
    font-size: clamp(11px, 3.0vw, 13px);
    line-height: 1.2em;
    margin-bottom: 15px;

    /* gap : 10px; */
}

.active{
    margin-top : 15px;
    margin-bottom: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
}


.addressContainer{
    /* border: 1px solid red; */
    display: flex;
    flex-direction:  column;
    width: 100%;
    font-family: 'Rubic' , sans-serif;
    font-size: clamp(6px, 2.6vw, 8px);
}


.actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    /* padding: 10px 10px 10px; */
    font-family: 'Rubic, sans-serif';
    font-weight: 400;
    font-size: clamp(8px, 2.8vw, 10px);
    line-height: 1.2em;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    
}

/* .point{
    width: 10xp;
    height: 10px;
}


.buttonCancel{
    width : 25%;
    height: 40px;
    border-radius: 10px;
    border:rgba(231, 79, 79, 1) 1.9px solid;
    color: rgba(231, 79, 79, 1);

    background-color: white;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: clamp(8px, 2.6vw, 10px);
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    /* margin-bottom: 10px; 
    box-shadow: 0 0 0 1px #f44336;


 */



