.row {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 70px;
    justify-content: space-between;
    /* border: 2px solid red; */
}

.logo {
    width: 30vw;
    height: auto;
    max-width: 127px;
    cursor: pointer;
}

.wrapper {
    padding: 20px 15px 10px;
    max-width: 428px;
    /*margin: 0 auto;*/
}

.header {
    /* padding-top: 20px; */
    background: white;
    z-index: 12;
    position: sticky;
    top: 0;
    /* left: 0;     */

    /*width: 100vw;*/
}

.wrapperMinimize {
    padding: 10px 15px 0;
    max-width: 428px;
    /*margin: 0 auto;*/
}

.chatsContainer {
    height: 41px;
    width: 41px;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: flex-end;
}

.chatsCountContainer {
    height: 15px;
    width: 15px;
    background-color: var(--shared-color);
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--dark-background-color);
    
}

.headerMinimize {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 12;
    background: white;
    width: 100vw;
    
}

.align {
    display: flex;
    column-gap: 17px;
    align-self: center;
}

.likesContainer {
    margin-top: 70px;
}
