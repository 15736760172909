.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    margin-bottom: 130px;
    font-family :'Rubik', sans-serif;

    overflow-y: scroll;
}

.titleContainer {
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding-top: 90px ;
    padding-bottom : 30px;
}   

.title {
    font-weight : 700;
    font-size: clamp(12px, 8vw, 26px);
    /* line-height: 1.2em; */
    text-align: center;
    /* padding: 10px  30px; */
    letter-spacing: 1px;

    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    color :rgba(0,0,0,1);
}

.text{
    color : rgba(96, 96, 96, 1);
    font-family : 'Saira', sans-serif;   
    font-weight : 700; 
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
}

.link{
    color: #4F8CE7;
    text-decoration: underline;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px;

}
.innText{

    color : black;
    font-family : 'Rubic', sans-serif;   
    font-weight : 500; 
    font-size: 1.2em;
    line-height: 1em;
    text-align: left;
    letter-spacing: 3%;
    width: 93%;
}

.smalText{
    color : rgba(96, 96, 96, 1);
    font-family : 'Rubic', sans-serif;   
    font-weight : 400; 
    font-size: 0.8em;
    line-height: 1.2em;
    text-align: left;
    width: 93%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.container {
    display: flex;
    align-items: flex-end; 

}
.inputWraper{
    flex-grow :1;
}

.inputContainer{
    display: flex;
    flex-direction: row;
    align-items: space-between;

    
}
.label {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    
    padding: 0 14px;
    margin-right : 35%;
}

.registeredInput {
    width: 97%;
    height: 35px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background-color : rgba(237, 237, 237, 1) ;

    /* background-color: #EDEDED; */
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top : 5px;
    margin-left: 5px;
    /* -webkit-appearance: none; */
    -moz-appearance: textfield;
}


/* .registeredInput:focus-visible { */
    /* outline: none; */
    /* background-color: rgba(237, 237, 237, 1); */

/* } */
.registeredInput::placeholder {
     /* font-family: var(--main-font); */
    /* color: rgb(54, 54, 54); */
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    color: black;


}

.registeredInputBig{
    
    /* position: relative; Add position relative to the input container */
        width: 100%;
        height: 70px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        background-color: rgba(237, 237, 237, 1);
        /* background-color: #EDEDED; */
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 5px;
        text-align: left; /* Align the text inside the input to the left */
        resize: none;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:10px 5px 10px 5px;
    margin-bottom: 10px;
    
}

.taxButton{
    width : 100%;
    height: 29px;
    border-radius: 10px;
    border: rgba(181, 181, 181, 1) 2px solid;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 10px; */

}

.active {
    border: 2px solid var(--primary-color);
}


  
  