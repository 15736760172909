.container {
    color: white;
    width: 100px;
    /* padding: 6px 10px 5px 10px; */
    padding-left: 10px;
    padding-top: 3px;

    
    background-color: var(--shared-color);
    height: 30px;
    border-radius: 10px;
    display:    flex;
    flex-direction: row;
    justify-content: space-around;
    /* gap: 5px; */
    /* background-color: var(--dark-background-color); */
}

.image{
    /* padding-left: 5px; */
    width: 17px;
    height: 17px;
    padding-top: 5px;   
    /* margin-right: 5px; */


}

.title {
    font-size: clamp(4px, 1.9vw, 8px);
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 8px;
    align-self: center;
}