.wrapper {
    padding: 0 4vw 10px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.addressWrapper {
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.05em;
    line-height: 120%;
    padding-top: 20px;
}

.addressTitle {
    font-weight: 700;
    font-size: 14px;

}

.wrapper h2 {
    padding: 18px 0 7px;
}

.addressContent {
    font-weight: 700;
    font-size: 10px;
    color: #606060;
    text-align: start;
    padding-top: 5px;
}

.addressControlTitles {
    padding-top: 11px;
}

.addressEdit {
    font-size: 14px;
    line-height: 10px;
    color: var(--primary-color);
}

.addressDelete {
    font-size: 14px;
    line-height: 10px;
    color: var(--shared-color);
    padding-left: 42px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.checkBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap:10px;
}

.deleteTitle {
    font-weight: 400;
    font-size: 18px;
    text-align: left;
}

.deleteText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--primary-color);
    margin: 15px 0 25px;
}