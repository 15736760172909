
/* for storage */
.wrapper{
    /* padding: 10px 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    /* background: rgba(96, 96, 96, 0.1); */
    margin-bottom: 110px;
    /* max-height: 54vb; You can adjust this value */
    /* overflow-y: auto;    */
}


.text{
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    margin: 15px;
}

.buttonContainer{
    display: flex;
    flex-direction: column;
    /* padding: 10px 10px; */
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
}

.addButton{
    width: 100%;
    height: 35px;
    background: rgba(79, 140, 231, 1);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
}
.addButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
    }
    
.addButtonFixed{
    position: fixed;
    bottom: 70px;
    left: 2.5%;  /* Add this property */
    width: 95%;
    height: 35px;
    background: rgba(79, 140, 231, 1);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    z-index: 999;
}


.storageContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    /* height: 50px; */
    padding: 10px 10px;
}

.labelContainer{  position: sticky;

    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.storageLabel, .items, .storageAddress, 
.changeButton, .deleteButton{
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(9px, 3.3vw, 13px);
}
.items, .changeButton{
    font-size: clamp(6px, 2.8vw, 8px);
    color: var(--primary-color);
}
.deleteButton{
    font-size: clamp(6px, 2.8vw, 8px);
    color: rgba(231, 79, 79, 1);
}

.storageAddress{
    display: flex;
    justify-items: flex-start;
    color: rgba(96, 96, 96, 1);
    font-size: clamp(6px, 2.9vw, 8px);

}
.changeButtonContainer{
    display: flex;
    justify-items: flex-start;
    gap : 20px;

}


