.root {
    /*padding-top: 50px;*/
    display: flex;
    flex-direction: column;
    /*height: 90vh;*/
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    padding-bottom: 80px;
}

.pageTitle {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    /*margin-top: 10px;*/
    background-color: #fff;
    padding: 10px 15px 15px;
}

.addressSelection {
    background-color: #fff;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
}

.addressText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.addressTitle {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-bottom: 7px;
}

.addressSelect {
    font-weight: 700;
    font-size: 10px;
    color: #4F8CE7;
    text-align: start;
}

.allItems {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
}

.selectAll {
    color: #4F8CE7;
    cursor: pointer;
}

.removeChosen {
    color: #E74F4F;
    cursor: pointer;
}

/* Render shops list */

.shopsList {
    /*margin-top: 15px;*/
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.shopBlock {
    margin-top: 15px;
    background-color: #fff;
    padding: 15px;
}

.shopHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.shopName {
    font-weight: 700;
    font-size: 14px;
    color: #000;
}

.shopRating {
    font-weight: 400;
    font-size: 10px;
    color: #606060;
}

/* Render CartItem */

.itemRoot {
    background: white;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    min-height: 150px;
    width: 100%;
}

.itemImage {
    margin-left: 15px;
    margin-right: 10px;
    height: 100%;

}

.itemImage img {
    width: 100%;
    max-width: 150px;
    height: auto;
}

.itemMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.itemInfo {
    display: flex;
    justify-content: space-between;
}

.info {

  text-align: left;
  max-width: 180px;

}

.itemCost {
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    margin-bottom: 10px;
}

.totalCost {
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    margin-bottom: 5px;
}

.discount {
    font-weight: 700;
    font-size: 8px;
    color: #4F8CE7;
    margin-bottom: 5px;
}

.bonuses {
    font-weight: 700;
    font-size: 8px;
    color: #606060;
    margin-bottom: 8px;
}

.itemName {

  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-bottom: 20px;
  display: block;
  text-align: left;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}

.like img {
    height: 15px;
}

.itemControls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    /*width: 180px;*/
    flex-grow: 1;
    overflow: hidden;
}

.aboutBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*max-width: 70%;*/
}

.about {

  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;

}

.buttonsBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 5px;
}

.progressTime {

}

.progress {

}

.deleteItem {
    font-weight: 400;
    font-size: 12px;
    color: #E74F4F;
    cursor: pointer;
}

.counter {
    display: flex;
}

.counter > span {
    margin: 0 10px;
}

/* Bonus section */

.addedBonuses {
    margin-top: 15px;
    padding: 15px;
    background-color: #fff;
}

.bonusCardsRoot {
    display: flex;
    flex-direction: column;
}

.bonusCardsTotal {
    font-weight: 700;
    font-size: 14px;
    color: #000;
}

.bonusCardWrapper {
    margin-top: 25px;
    display: flex;
}

.bonusCard {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    background: #EDEDED;
    border-radius: 10px;
    margin-right: 10px;
}

.bonusCardName {
    font-weight: 700;
    font-size: 12px;
    color: #000;
    margin-bottom: 10px;
}

.bonusCardCount {
    font-weight: 400;
    font-size: 14px;
    color: #000;
}

/* Total section */

.totalSection {
    margin-top: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    text-align: left;
}

.totalHeader {
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: left;
}

.totalCosts {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.totalItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.totalItemsCount {
    display: flex;
    flex-direction: column;
}

.totalItemsDiff {
    font-weight: 400;
    font-size: 20px;
    color: #000;
}

.totalItemsAll {
    font-weight: 400;
    font-size: 12px;
    color: #B5B5B5;
}

.totalItemsPrice {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.totalDiscount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalDiscountTitle {
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.totalDiscountSum {
    font-weight: 700;
    font-size: 16px;
    color: #E74F4F;
}

.totalDivider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 15px 0 20px 0;
    background-color: #000;
}

.finalCost {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finalCostTitle {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.finalCostSum {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.proceedBtn {
    padding: 10px 0;
    width: 90%;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    background: #4F8CE7;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
}

.disabled {
    background: #ababab;
    pointer-events: none;
}

@media screen and (max-width: 390px) {
    .shopBlock {
        padding: 8px;
    }
}

@media screen and (max-width: 360px) {
    .itemName {
        margin-bottom: 15px;
    }

    .itemImage {
        width: 40%;
        margin-left: 10px;
    }

    .shopBlock {
        padding: 5px;
    }
}


.inputText {
    border: none;
    width: 35px;
    background: none;
    align-items: flex-start;
    justify-content: flex-start;
    outline: none;
    text-align: center;
    flex: 1;
   
}

.inputText[type='number'] {
    -moz-appearance: textfield;
  }

  .inputText[type='number']::-webkit-inner-spin-button,
  .inputText[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }



/* for error screen */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it's above other content but below the modal */
}

.errorModal {
    position: fixed;
    left: 50%;   
    bottom: 170px;
    transform: translateX(-50%);
    background-color: white;
    border-top: 2px solid red;
    padding: 10px;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
    z-index: 1000; /* Above the overlay */
}


.errorButtonContainer{
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    gap : 5px;

}