.wrapper {
    margin-bottom: 79px;
}

.videoWrapper {
}

.videoContent {
    width: 100%;
    height: auto;
    position: relative;
    /*padding: 0 0 50vh 0;*/
}

.videoItem {
    object-fit: cover;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    height: auto;
    width: 100%;
}