


.content {
    width: clamp(105px, 32vw, 155px);
    /* padding: 8px; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* row-gap: 6px; */
    justify-content: center;
    align-items: center;
    /* border-top: 2px solid var(--dark-background-color); */
    /* border-bottom: 2px solid var(--dark-background-color); */
    border-radius: 10px;
    border: 2px solid var(--dark-background-color);

}

.active {
    border: 3px solid var(--primary-color);
}

/* .content:first-child {
    border-radius: 10px 0 0 10px;
    border: 2px solid var(--dark-background-color);
}

.content:last-child {
    border-radius: 0 10px 10px 0;
    border: 2px solid var(--dark-background-color);
} */



/* .title {
    text-transform: uppercase;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-align: center;
} */


.price {
    flex-wrap: nowrap;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(16px, 3.6vw, 18px);
}

.minQuantity {
    color: #606060;
    margin-top: 3px;
    margin-bottom: 5px;
    font-size: clamp(10px, 3.2vw, 12px);
    font-weight: 700;


}

.buyerСounter {
    font-size: clamp(8px, 3.0vw, 10px);
    display: flex;
    align-self: center;
    font-weight: 400;
    gap: 3px;
    /* line-height: 12px; */
    letter-spacing: 0px;
    margin-bottom: 2px;
    
}

