.wrapper {
    /*flex-grow: 1;*/
}

.registeredInput {
    width: 100%;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    background-color: #EDEDED;
    border: none;
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
}

.registeredInput:focus-visible {
    outline: none;
}

.registeredInput::placeholder {
    color: #606060;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.label {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /*padding: 0 14px;*/
}