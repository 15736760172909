.title {
	width: 393px;
	height: 12px;
	left: 14px;
	/* top: 213px; */

	margin-bottom: 10px;
	font-family: 'PT Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 12px;

	/* identical to box height, or 86% */
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;

}

.photo {

	width: 100px;
	height: 100px;
	margin-top: 10px;
	display: flex;


	object-fit   : cover;
	border-radius: 10px;
}

.container {
	display   : flex;
	column-gap: 5px;
	overflow-y: auto;
	
}