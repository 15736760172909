/* .container {
    background-color: var(--dark-background-color);
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 5px 16px;
    border-radius: 10px;
    gap: 2vw;
}

.active {
    background-color: var(--primary-color);
}

.timerContainer {
    display: flex;
    gap: 3px;
    align-items: center;
} */

.timeContent {
    white-space: nowrap;
    font-size: clamp(8px, 2.8vw, 10px);
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    word-spacing: 0px;

}

/* .box {
    display: flex;
    align-items: center;
    /* gap: 3px; */
/* } */

/* .box > span { */

/* } */