.item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 390px;
    /* row-gap: 10px; */
    font-family: 'Rubik', sans-serif;
}

.slide {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 310px;

    /* overflow:hidden; */
    /* border: 2px solid green; */
  
}
.GoodsMainSlider {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* width: 100%; */
    /* height: 310px; */
    overflow: hidden;
    
}

/* .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;    

    width: 100%;          
    height: 100%;    
    border: 2px solid green;     
} */

    .sliderImage {
        display: flex;
        align-self: center;
        max-width: 380px;     
        height: 310px;
        object-fit: cover;
        /* border: 2px solid var(--dark-background-color); */
    }


.PriceContainer {
    display: flex;
    align-items: flex-end;
    column-gap: 10px;
}


.ProductContent {
    /* padding: 6px 7px; */
    font-family: 'Rubik', sans-serif;
    text-align: left;
    padding-left: 5px;
    /* max-width: 70%; */

    /* border: 2px solid red; */
    /* padding-right: -40px; */
}



.ordinaryPrice {
    font-size: 1em;
    font-weight: 400;
    font-family: "Exo 2", sans-serif;
    text-decoration: line-through;
}

.Price {
    font-size: 1.4em;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
    text-align: left;
}


.itemInnerContainer {
    /*display: flex;*/
    /*justify-content: space-between;*/
}



.ProductContent {
    padding: 6px 7px;
    text-align: left;
}

.about {
    font-size: 10px;
    font-weight: 400;

    padding: 9px 0;
}

.subCategoryContent {
    font-weight: 400;
    font-size: 8px;
    color: var(--dark-background-color)
}

.star {
    width: 12px;
    margin-right: 3px;
}

.starCount {
    display: inline-block;
    margin-left: 10px;
}


.shopNameContent{
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: clamp(8px, 10px, 12px);
    gap : 5px;
}

.ratingContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    width: 100%;  
    margin-left: -15px;
}

























