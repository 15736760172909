.wrapper {

    display: flex;
    padding: 10px 10px 10px;
    flex-direction: column;
    row-gap: 2vh;
}

.buttonContainer {
    margin: 0 auto;
    width: 92%;
}