.onePersonReview {
    padding-top: 20px;
    text-align: left;
    letter-spacing: 0.05em;
}

.star {
    width: 15px;
    height: auto;
}

.alignContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 15px;
}

.usersLetterContainer {
    height: 30px;
    width: 30px;
    background-color: #D9D9D9;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.usersLetter {
    color: #606060;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.userName {
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    margin-left: 15px;
}

.date {
    font-size: 8px;
    line-height: 8px;
    margin-top: 2px;
    color: #606060;
    text-align: right;
}

.productPropertyName {
    font-size: 14px;
}

.productProperty {
    font-size: 12px;
    color: #606060;
}

.propertyTitle {
    font-size: clamp(10px, 1.9vw, 12px);
    line-height: 20px;
    margin-top: 15px;
}

.propertyContent {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #606060;
}

.userPhotoContainer {
    display: flex;
    column-gap: 5px;
}

.photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin: 25px 0 15px;
}

.text {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 15px;
}

.buttonYN {
    width: 100px;
    background: #EDEDED;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    padding: 9px;
    border: 2px solid transparent;
}

.buttonYN:first-child {
    margin-right: 10px;
}

.active {
    background: linear-gradient(#EDEDED, #EDEDED) padding-box,
    linear-gradient(90deg, #ef6666 0%, #0628f1 49.48%, #ef72f2 100%) border-box;
}

.attentionContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
}

.attentionImage {
    position: relative;
}

.attentionText {
    position: absolute;
    left: 8.5px;
    top: 1px;
    color: #606060;
}

.hidden {
    display: none;
}


.line {
    width: 100%;
    height: 1px;
    background-color: black;
    /* margin-top: 15px; */
}