.wrapper {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 10px;
    margin-bottom: 15px;
}

.greeting {
    padding-top: 10%;
    padding-bottom: 5%;
}

.title {
    text-align: center;
    font-size: 26px;
    line-height: 18px;
    padding-bottom: 5%;
}

.text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
}

.line{
    width: 100%;
    height: 15px;
    background: rgba(96, 96, 96, 0.1);
    
    margin: 10% 0;
}

.numberContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}
.buttonCode{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: white;
    font-size: 10px;
    height: 35px;
    width: 90px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    background-color: var(--primary-color);
}
.buttonCode:disabled{
    background-color: var(--dark-background-color);
}

.submitContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 40px;
    
}

.submitButton{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    
    color: white;
    font-size: 16px;
    letter-spacing: 2px;
    height: 35px;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: var(--primary-color);
}

.submitButton:disabled{
    background-color: var(--dark-background-color);
}

.icon{
    margin-left: 5px;
    margin-right: 7px;
}

.link{
    display: flex;
    margin-top : 25px;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: var(--primary-color);
    text-decoration: none;
}