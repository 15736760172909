.ContainerSingle {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    margin-bottom: 50px;
    /* justify-content: space-between; */
    row-gap: 2vh;
}

.ContainerDouble{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    justify-content: space-between;
    row-gap: 2vh;
}

.ContainerHorizontal {
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    margin-bottom: 50px;
    justify-content: space-between;
    gap: 10px;
    /* border: 2px solid red;   */
}

.goodsItemDouble {
    position: relative;
    width: 48%;
}

.goodsItemSingle {
    position: relative;
    width: 100%;
}

.ContainerHorisontal::-webkit-scrollbar {
    width: none;
}

.ContainerHorisontal > div {
    width: 48vw;
    row-gap: 2vw;
    margin-right: 2vw;
}

.backet {
    width: 20px;
    height: 20px;
}

.addButton {
}

.addContainer {
    position: absolute;
    bottom: 17px;
    right: 7px;
}


.amount {
    display: inline-block;
    width: 24px;
    font-size: 12px;
    text-align: center;
}

.deleteItem {
    text-align: right;
    z-index: 3;
}