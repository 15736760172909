.wrapper {
    padding: 10px 15px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* font-family: var(--main-font); */
    /* font-family: 'Rubic', sans-serif; */
    font-style: normal;
    font-weight: 700;
    /* line-height: 40px; */
    margin-bottom: 80px;
}


.headerContainer{
    display: flex;
    flex-direction: row;
    /* background: white; */
    /* justify-content: space-between; */
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    /* justify-content: center; */
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;  
    width: 100%;
    font-size: clamp(8px, 3.0vw, 12px);
}


.step {
    font-weight: 400;
}

.search {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    padding-left: 10px;
    font-size: clamp(8px, 3.4vw, 14px);


}

.search::placeholder {
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 3.4vw, 14px);
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-top: 15px;
    color: rgba(181, 181, 181, 1);
}

.cthrow {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-color);
    font-size: clamp(8px, 3vw, 14px);
}

.allCategory {
    display: flex;
    justify-content: flex-start;
    font-size: clamp(8px, 3vw, 14px);
    font-weight: 400;
    margin-bottom: 15px;
    padding-left: 10px;
}

.allSubCategory {
    display: flex;
    justify-content: flex-start;
    font-size: clamp(8px, 3vw, 14px);
    font-weight: 400;
    margin-bottom: 15px;
    color: var(--primary-color);
}


.categoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    font-size: clamp(8px, 3vw, 14px);
    padding-right: 15px;

    padding-left: 10px;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.categoryName {
    display: flex;
    align-items: center;
    height: 35px;
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 3vw, 14px);
    font-weight: 500;
    letter-spacing: 0.05em;
    text-align: left;
    border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.addCategory {
    position: fixed;
    bottom: 70px;
    /* left: 10px; */
    display: flex;
    margin-top: 15px;
    width: 90%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    justify-content: center;
    align-items: center;
    /*margin: 1%;*/
    cursor: pointer;
}

.addCategory:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}


.brandList {
    max-height: 54vb; /* You can adjust this value */
    overflow-y: auto;

    /* max-height: calc(100% - 50px);  */
}

/* .addButton { 
    position: fixed;
    bottom: 70px;
    left: 0;    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 90%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: white;

    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;

    cursor: pointer;

}

.addButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}
*/


/* .color,
.colorActive {
  width: 20px;
  height: 20px;
  display: inline-block;
}
*/


.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.formItem {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 5px;
}

.chooseForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;

}

.inputContainer {
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    background: rgba(237, 237, 237, 1);
    border-radius: 10px;
    border: 2px solid rgba(237, 237, 237, 1);
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* font-size: clamp(8px, 3.2vw, 12px); */
    font-size: clamp(16px, 3.4vw, 18px);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

}

.label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(8px, 3.2vw, 12px);
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
}

.inputField {
    border: none;
    background: rgba(237, 237, 237, 1);


}

.input {
    border: none;
    height: 35px;
    width: 99%;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* font-size: clamp(8px, 3.2vw, 12px); */
    font-size: clamp(16px, 3.4vw, 18px);
    padding-left: 10px;

}

.inputBig {

    width: 100%;
    height: 160px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    /* font-size: clamp(8px, 3.2vw, 12px); */
    font-size: clamp(16px, 3.4vw, 18px);
    background-color: rgba(237, 237, 237, 1);
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    text-align: left;
    resize: none;
}

.formCharacter {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin-bottom: 15px;
    gap: 10px

}

.labelIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

}

.characterContainer {

    display: flex;
    flex-direction: row;
    width: 98%;
    gap: 15px;
    color: rgba(237, 237, 237, 1);
    border-radius: 10px;
    gap: 10px /* justify-con
      width: 100%;
        height: 160px;
        font-family: 'Rubik', sans-serif; 
        font-style: normal;
        font-weight: 400;
        font-size: clamp(8px, 3.2vw, 12px);
        background-color: rgba(237, 237, 237, 1);
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 5px;
        text-align: left;
        resize: none;

    
    tent: space-between; */
}

.inputCharacter {
    width: 100%;
    height: 35px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* font-size: clamp(8px, 3.2vw, 12px); */
    font-size: clamp(16px, 3.4vw, 18px);
    padding-left: 10px;
}

.addCharacter {
    color: rgba(79, 140, 231, 1);
    margin-top: 5px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    font-size: clamp(8px, 3.2vw, 12px);
    
    padding-left: 10px;

}

.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-bottom: 15px;
    gap: 10px
}

.boxButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 30px;
    background: rgba(237, 237, 237, 1);
    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(8px, 3.2vw, 12px);
    cursor: pointer;
}

.active {
    border: 2px solid var(--primary-color);
}


.infoTitle {
    font-family: 'Rubik', sans-serif;
    font-size: clamp(10px, 3.8vw, 16px);
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 20px;

}

.infoText {
    width: 100%;
    font-family: 'Rubik', sans-serif;
    font-size: clamp(8px, 3.4vw, 13px);
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    /* margin-bottom: 30px; */
}

.errorContainer {
    height: 1rem;
    margin-top: 5px;
}

.errors {
    color: red;
    margin-left: 10px;
    position: relative;
}

.errors::before {
    display: inline-block;
    width: 10px;
    content: "⚠  ";
    position: absolute;
    left: 0;
}


