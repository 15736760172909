.wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: calc(100vh - 130px);
}

.title {
    font-size: 20px;
    line-height: 120%;
    margin-top: 10px;
    margin-bottom: 12px;
}

.container {
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 3px;
}

.text {
    vertical-align: center;
    align-self: center;
    font-weight: 700;
    font-size: 10px;
}

.buttonContainer {
    flex-grow: 1;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}