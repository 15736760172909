.Likes{
	width: 17px;
	height: auto;
}
.LikesContainer{
	position: absolute;
	width: 20px;
	height: 20px;
	right: 10px;
	top: 10px;
	z-index: 4;
}