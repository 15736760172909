.wrapper {
    padding: 35vh 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.title1 {
    text-align: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    padding: 0;

    vertical-align: bottom;
}

.title2 {
    margin-top: 5%;
    padding: 0;
    font-size: 36px;
}

.text {

}

.item {
    font-weight: 900;
    font-size: 36px;
    vertical-align: bottom;
}

.title3 {
    padding: 0;

    text-align: center;
}

.title4 {
    font-size: 2rem;
    padding: 6vh 0;
    text-align: center;
}

.title5 {
    font-size: 1.5rem;
    padding: 0;
}