.container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 14px;
    row-gap: 20px;
    padding-top: 20px;
}

.wrapper {
    display: flex;
    row-gap: 20px;
    flex-direction: column;
}

.verifyWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 14px;

}

.verifyContent {
    display: flex;
    align-items: flex-end;
}

.message {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-align: left;
}

.text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    /* or 86% */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
}

.timerButton {
    display: inline-block;
    height: 32px;
    width: 86px !important;
    background: #3300FF;
    border-radius: 10px;
    border: none;
    font-family: var(--main-font);
    font-style: normal;
    letter-spacing: 0.075em;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-left: 14px;
}

.timerButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: #606060;
}

.checkButton {
    display: inline-block;
    border-radius: 10px;
    border: none;
    font-family: var(--main-font);
    font-style: normal;
    letter-spacing: 0.075em;
    padding: 10px 0;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    min-width: 110px;
}

.checkButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: #606060;
}

.success {
    background: #3300FF;
}

.fail {
    background: red;
}

.applyButton {
    background: #3300FF;
    border-radius: 10px;
    border: none;
    font-family: var(--main-font);
    font-style: normal;
    letter-spacing: 0.075em;
    padding: 10px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.applyButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: #606060;
}

.confirm {
    background: #00C62B;
}

.resend {
    width: 15px;
    height: auto;
}