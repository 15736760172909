.img {
    /*stroke: url(#paint0_linear_37_410);*/
    color: black;
}

.activeImg {
    stroke: url(#paint0_linear_37_410);
    /*stroke: black;*/
}

.linkContainer {
    display: flex;
    align-items: flex-end;
}
