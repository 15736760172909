.wrapper {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
}

.greeting {
    padding: 25% 0;
}

.title {
    text-align: center;
    font-size: 26px;
    line-height: 18px;
    padding-bottom: 5%;
}

.text {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
}