.container {
	display: flex;
	font-size: clamp(10px, 3.0vw, 12px);
	font-family: 'Rubic', sans-serif;
	
}

.keys,
.propets {
	margin-top: 10px;
	width       : 60%;
	text-align  : left;
	padding-left: 6px;
	

}

.propets {
	width: 40%;
	/* text-align: right; */
	font-family: 'Rubic', sans-serif;
	font-size: clamp(10px, 3.0vw, 12px);
	font-weight: 400;
	margin-top : 10px;
}
.keys {
	display       : flex;
	text-transform: capitalize;
}

.dot {
	border-bottom: 2px dotted #000;
	flex         : 1;
}

.react_tabs_tab {
	width: 50% !important;
	font-family: 'Rubic', sans-serif;
	font-size: clamp(12px, 3.2vw, 14px);
	font-weight: 400;
}

.about{
	font-family: 'Rubic', sans-serif;
	font-size: clamp(10px, 3.0vw, 12px);
	font-weight: 400;
	margin-top : 10px;
}