.Slide {
    width: 30%;
    min-width: 100px;
    background: var(--primary-color);
}

.topLeft {
    position: absolute;
    bottom: 12px;
    font-size: 1em;
    line-height: 90%;
    color: white;
    text-transform: uppercase;
    text-align: center;
    padding: 0 5px;
    width: 100%;
}

.img {
    height: 60px !important;
    margin: 24px auto 46px !important;
    object-fit: contain !important;
}

.title {
    padding: 1.5vh;
    text-transform: uppercase;
}