.applyButton {
    height: 35px;
    width: auto;
    border-radius: 8px;
    border: none;
    font-family: var(--main-font);
    /* font-style: normal; */
    letter-spacing: 0.075em;
    padding: 10px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    width: 110px;
    margin-left: 10px;
}

.applyButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: #606060;
}

.check {
    background-color: #3300FF;
}

.valid {
    background-color: rgba(79, 231, 112, 1);
}

.error {
    background-color: red;
}