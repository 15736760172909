.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    margin-bottom: 130px;
    

    overflow-y: scroll;
}


.titleContainer {
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding-top: 90px ;
    padding-bottom : 30px;
}   

.foto{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px ;
    padding-bottom : 30px;
    color : rgba(237, 237, 237, 1)
}

.avatarContainer {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: url('../../../assets/png/camera.png') ;
    background-color: var(--light-background-color);
    background-size: 40%;

    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 20px;
    margin-bottom: 30px;


}

.avatar {
    object-fit: 80%;
    width: 100%;
    height: 100%;
}
.newAvatar {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    /*border: 1px solid red;*/
    object-fit: contain;
    cursor: pointer;
}

.noPhoto {
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 22px;
}



.title {
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: 2.6em;
    line-height: 1.2em;
    text-align: center;
    /* padding: 10px  30px; */
    padding-bottom: 30px;
    color :rgba(0,0,0,1);
}

.text{
    color : rgba(96, 96, 96, 1);
    font-family : 'Saira', sans-serif;   
    font-weight : 700; 
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
}

.link{
    color: #4F8CE7;
    text-decoration: underline;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px;

}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.label {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    
    padding: 0 14px;
    margin-right : 35%;
}

.registeredInput {
    width: 97%;
    height: 35px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: clamp(16px, 3.4vw, 18px);
    /* font-size: clamp(12px, 3.4vw, 14px); */
    background-color : rgba(237, 237, 237, 1) ;

    /* background-color: #EDEDED; */
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top : 5px;
    margin-left: 5px;
    /* -webkit-appearance: none; */
    -moz-appearance: textfield;
}
.registeredInput:read-only {
    background-color: rgba(237, 237, 237, 1); /* Or any other color you want */
    /* Add other styles as needed */
}
.registeredInput:read-only::placeholder {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #363131; /* Change this color to whatever you need */
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top:10px ;
    margin-bottom: 10px;
    gap : 10px;

    /* row-gap: 15px; */
}

.dealButton{
    width : 100%;
    height: 31px;
    border-radius: 10px;
    border: rgba(181, 181, 181, 1) 2px solid;
    font-family: var(--main-font);
    font-weight: 400;
    font-size: clamp(4px, 2.8vw, 12px);
    line-height: 10px;  
    /* text-align: center; */
    /* cursor: pointer; */
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    /* margin-bottom: 10px; */

}
.active {
    border: 2px solid var(--primary-color);
}

.bicText{

    color : black;
    font-family : 'Rubic', sans-serif;   
    font-weight : 500; 
    font-size: 1.2em;
    line-height: 0.8em;
    letter-spacing: -0.5px;


    text-align: left;
    width: 93%;
}

.smalText{
color : rgba(96, 96, 96, 1);
font-family : 'Rubic', sans-serif;   
font-weight : 400; 
font-size: 0.8em;
line-height: 1.2em;
text-align: left;
width: 93%;
margin-top: 5px;
margin-bottom: 10px;
letter-spacing: 0px;

}

.bicContainer {
    display: flex;
    align-items: flex-end; 

}
.registeredInputBig{
    
    /* position: relative; Add position relative to the input container */
        width: 97%;
        height: 50px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        background-color: rgba(237, 237, 237, 1);
        /* background-color: #EDEDED; */
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top : 5px;
        margin-left: 5px;
        resize: none;
        
        
        
}