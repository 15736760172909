.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    margin-bottom: 130px;
    font-family :'Rubik', sans-serif;

    overflow-y: scroll;
}

.titleContainer {
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding-top: 90px ;
    padding-bottom : 30px;
}   

.title {
    font-weight : 700;
    font-size: clamp(12px, 8vw, 26px);
    /* line-height: 1.2em; */
    text-align: center;
    /* padding: 10px  30px; */
    letter-spacing: 1px;

    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    color :rgba(0,0,0,1);
}

.text{
    color : rgba(96, 96, 96, 1);
    font-family : 'Saira', sans-serif;   
    font-weight : 700; 
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
}

.link{
    color: #4F8CE7;
    text-decoration: underline;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px;

}
.innText{

    color : black;
    font-family : 'Rubic', sans-serif;   
    font-weight : 500; 
    font-size: 1.2em;
    line-height: 1em;
    text-align: left;
    letter-spacing: 3%;
    width: 93%;
}

.smalText{
    color : rgba(96, 96, 96, 1);
    font-family : 'Rubic', sans-serif;   
    font-weight : 400; 
    font-size: 0.8em;
    line-height: 1.2em;
    text-align: left;
    width: 93%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.container {
    display: flex;
    align-items: flex-end; 

}
.inputWraper{
    flex-grow :1;
}

.inputContainer{
    display: flex;
    flex-direction: row;
    align-items: space-between;

    
}
.label {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    
    padding: 0 14px;
    margin-right : 35%;
}

.registeredInput {
    width: 97%;
    height: 35px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background-color : rgba(237, 237, 237, 1) ;

    /* background-color: #EDEDED; */
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top : 5px;
    margin-left: 5px;
    /* -webkit-appearance: none; */
    -moz-appearance: textfield;
}


/* .registeredInput:focus-visible { */
    /* outline: none; */
    /* background-color: rgba(237, 237, 237, 1); */

/* } */
.registeredInput::placeholder {
     /* font-family: var(--main-font); */
    /* color: rgb(54, 54, 54); */
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    color: black;


}

.registeredInputBig{
    
    /* position: relative; Add position relative to the input container */
        width: 100%;
        height: 70px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        background-color: rgba(237, 237, 237, 1);
        /* background-color: #EDEDED; */
        border: none;
        border-radius: 10px;
        padding: 10px;
        margin-top: 5px;
        text-align: left; /* Align the text inside the input to the left */
        resize: none;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:10px 5px 10px 5px;
    margin-bottom: 10px;
    
}

.taxButton{
    width : 100%;
    height: 29px;
    border-radius: 10px;
    border: rgba(181, 181, 181, 1) 2px solid;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 18px; */
    /* text-align: center; */
    /* cursor: pointer; */
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 10px; */

}

.active {
    border: 2px solid var(--primary-color);
}



.applyButton {
    height: 35px;
    width: auto;
    border-radius: 8px;
    border: none;
    font-family: var(--main-font);
    /* font-style: normal; */
    letter-spacing: 0.075em;
    padding: 10px;
    color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    width: 110px;
    margin-left: 10px;
}

.applyButton:disabled {
    background: #EDEDED;
    cursor: default;
    border: none;
    color: #606060;
}

.check {
    background-color: #3300FF;
}

.valid {
    background-color: rgba(79, 231, 112, 1);
}

.error {
    background-color: red;
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: 0px;
}

.addressSelection {
    background-color: #fff;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
}

.addressText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.addressTitle {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-bottom: 7px;
}

.addressSelect {
    font-weight: 700;
    font-size: 10px;
    color: #4F8CE7;
    text-align: start;
}

.coloredButton {
    width: 100%;
    /*background: linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%);*/
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    /*margin: 1%;*/
    cursor: pointer;
}

.coloredButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}




.totalSection {
    margin-top: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    text-align: left;
}

.totalHeader {
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: left;
}

.totalCosts {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.totalItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.totalItemsCount {
    display: flex;
    flex-direction: column;
}

.totalItemsDiff {
    font-weight: 400;
    font-size: 20px;
    color: #000;
}

.totalItemsAll {
    font-weight: 400;
    font-size: 12px;
    color: #B5B5B5;
}

.totalItemsPrice {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.totalDiscount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalDiscountTitle {
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.totalDiscountSum {
    font-weight: 700;
    font-size: 16px;
    color: #E74F4F;
}

.totalDivider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 15px 0 20px 0;
    background-color: #000;
}

.finalCost {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finalCostTitle {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.finalCostSum {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.localModalContent{
    max-height: 60vh;
    overflow-y: auto;
}