.container {
    height: 200px;
    width: 100%;
}

.sliderVideo {
    object-fit: cover;
    width: 100%;
    height: auto;
    transform: translateY(-20%);
}

.topSlider {
    border-radius: 12px;
    margin-top: 6px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-height: 156px;
    overflow: hidden;
}
