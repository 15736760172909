.coloredButton {
    width: 100%;
    /*background: linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%);*/
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    color: white;
    /*margin: 1%;*/
    cursor: pointer;
    margin-top: 10px;
}

.coloredButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
}