.App {
    text-align: center;
    max-width: 429px;
    margin: 0 auto;
    position: relative;
    letter-spacing: 0.05em;
    font-family: 'Rubik', sans-serif;
    /*min-height: 100vh;*/
    /*font-family: 'PT Sans', sans-serif;*/
}

.Container {
    display: flex;
    flex-wrap: wrap;
}

.Container > div {
    width: 48%;
    margin: 3px;
}