.wrapper {
    /* padding: 90px 0 60px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    background: rgba(96, 96, 96, 0.1);
    

    overflow-y: scroll;
}

.titleContainer {
    width: 100%;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 10px 15px 30px;
}   

.title {
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: 2.6em;
    line-height: 1.2em;
    text-align: center;
    padding: 10px 15px 30px;
    color :rgba(0,0,0,1);
}

.text{
    color : rgba(96, 96, 96, 1);
    font-family : 'Saira', sans-serif;   
    font-weight : 700; 
    font-size: 1em;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
}

.link{
    color: #4F8CE7;
    text-decoration: underline;
}

.form {
    background:white;
    width: 100%;
    margin-top: 15px;   
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 13px;

}
.innText{

    color : black;
    font-family : 'Rubic', sans-serif;   
    font-weight : 500; 
    font-size: 1.2em;
    line-height: 1em;
    text-align: left;
    letter-spacing: 3%;
    width: 93%;
}

.smalText{
    color : rgba(96, 96, 96, 1);
    font-family : 'Rubic', sans-serif;   
    font-weight : 400; 
    font-size: 0.8em;
    line-height: 1.2em;
    text-align: left;
    width: 93%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.container {
    display: flex;
    align-items: flex-end; 

}

.inputContainer{
    display: flex;
    flex-direction: row;
    align-items: space-between;

    
}



