.wrapper{
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    
    margin-bottom: 110px;
  

}
.title {
    font-weight: 600;
    font-size: 16px;
    /* padding-bottom: 27px;     */
    letter-spacing: 0.075em;
}

.findContainer {
    display: flex;
    align-items: center;
    width: 100%;    
    
  }
  
  .search {
    width: 90%;
    height: 35px;
    border-radius: 10px 0 0 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    padding-left: 10px;
    font-size: clamp(8px, 3.4vw, 14px);
  }
  
  .find {
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 0 10px 10px 0;
    border: none;
    background: rgba(79, 140, 231, 1);
    color: #606060;
    padding: 0 10px;
  }
  
  .buttonText {
    font-size: 14px;
    letter-spacing: 0.075em;
  }
  
  .searchSvg {
    margin-left: 5px;
    filter: invert(60%) sepia(1%) saturate(2829%) hue-rotate(303deg) brightness(116%) contrast(86%);
  }

.citiesContainer{
    gap : 30px;
}
 
  .cityContainer{
    font-family: 'Rubik', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height:35px;
    font-size: clamp(8px, 3vw, 14px);
    padding-right: 15px;
    
    padding-left: 10px;
    /* border-bottom: 1px solid rgba(237, 237, 237, 1); */
}

.cityInfo{
    display: flex;
    flex-direction: row;
    align-self: start;
    justify-content: flex-start;
    gap : 10px;
}
.city{
    gap :5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.cityName{
    font-weight: 600;
    /* font-size: 14px; */
    letter-spacing: 0.075em;
    color: black;
}
.cityCountry{
    font-weight: 400;
    font-size: clamp(6px, 2.8vw, 10px);
    color: #606060;

}

.addButton{
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0;     */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 98%;
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: white;

    border-radius: 10px;
    border: none;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: clamp(8px, 3.4vw, 16px);
    line-height: 12px;
    letter-spacing: 0.075em;
    padding: 14px;
    
    cursor: pointer;

}
.addButton:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;

    }

.form{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap : 10px;
}

.input{
        border: none;
        height: 35px;
        width: 99%;
        border-radius: 10px;
        background: rgba(237, 237, 237, 1);
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        font-size: clamp(8px, 3.2vw, 12px);
        padding-left: 10px;
        
    
    }
    
.flatContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 10px;
    font-size: clamp(8px, 3.2vw, 12px);
    font-weight: 501;   

}
.textarea{
    width: 100%;
    height: 100px;
    font-family: 'Rubik', sans-serif; 
    font-style: normal;
    font-weight: 400;
    font-size: clamp(8px, 3.2vw, 12px);
    background-color: rgba(237, 237, 237, 1);
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    text-align: left;
    resize: none;
}