.wrapper {
    padding: 90px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    bottom: 0;
    height: 100vh;
}

.form {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.title {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 2.6em;
    line-height: 1.8em;
    text-align: center;

}

.container {
    display: flex;
    align-items: flex-end;
    padding-right: 14px;
}

.text {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.titleContent {
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    margin: auto 0;
}