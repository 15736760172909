.wrapper{
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    
    margin-bottom: 110px;
 
}

.contentContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;  
}


.shopInfo {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.photo {
    margin-right: 15px; 
}

.photo img {
    width: 60px; 
    height: 60px; 
    object-fit: cover; /*covers the entire dimensions specified without distortion */
}

.infoContainer {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.shopName {
    font-size: clamp(6px, 3.4vw, 16px);
    font-weight: 600;
    /* line-height: 12px;  */
    color: rgba(0, 0, 0, 1);
    /* letter-spacing: 0px; */

}

.shopSlogan {
    font-size: clamp(5px, 3.2vw, 14px);; 
    font-weight: 400;
    /* letter-spacing: 0px; */
}

/*
.performanceContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

  }
  
  .performanceMain{
    display: flex;
    width :100%;
    height: 35px;
    flex-direction: row;
    gap: 10px;
    
  }
  .performanceMainItem{
    width: 100%;
    display: flex;
    flex-direction: row;
    background: rgba(237, 237, 237, 1);
    justify-content: space-around;
    border-radius: 10px;
    
  }
.performanceMainLabel{
    display: flex;
    align-items: center;
    font-weight: 501;
    font-size: clamp(5px, 3.2vw, 14px);
    }
.performanceMainValue{
        display: flex;  
        font-size: clamp(5px, 3.4vw, 14px);
        font-weight: 600;
        align-items: center;
    }
      

  
.performance{
    width: 100%;
    display: flex;line{
        width: 100%;
    
        height: 15px;
        background: rgba(237, 237, 237, 1);
        margin-top: 20px;
        margin-bottom: 20px;
    }
    flex-direction: row;
    gap: 5px;

  }

.performanceItem{
    width: 24%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(237, 237, 237, 1);
    border-radius: 10px;     
  }

.performanceItemLabel{
    font-weight: 501;
    font-size: clamp(4px, 2.5vw, 10px);
}

.performanceItemValue  {
    font-size: clamp(4px, 3vw, 12px);
    font-weight: 550;
    text-align: center;

 }
*/




.text{
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    margin: 15px;
}

.addButton{
    width: 100%;
    height: 40px;
    background: rgba(79, 140, 231, 1);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 7  00;
    font-size: 0.9rem;
}

.line{
    width: 100%;

    height: 15px;
    background: rgba(237, 237, 237, 1);
    margin-top: 20px;
    margin-bottom: 20px;
}

.search{
    width: 100%;
    height: 40px;
    margin-top: 15px;
    /* margin-bottom: 20px; */
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    padding-left: 10px;
    font-size: clamp(8px, 3.4vw, 14px);
}

.itemContainer{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;

}



.itemImage{
    margin-right: 15px; 
    }
.itemImage img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    /* margin-right: 15px; */
}


.itemInfo{
    width: 55%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-align: left;
    font-family: 'Rubik', sans-serif;
}

.itemArticle{
font-size: clamp(6px, 2.8vw, 8px);

}

.itemName{
font-size: clamp(8px, 3.4vw, 12px);
}


.itemPurposeCount{
font-size: clamp(5px, 2.6vw, 7px);
}

.itemStatus{
    background: rgba(231, 161, 79, 1);
    height: 25px;
    width: 60%;   
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;  
    color: white;
    font-size: clamp(8px, 3.0vw, 12px);   
    align-self: flex-end;
}


.countLots{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    font-family: Rubik;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-align: center;
    /* padding-left: 15px; */
} 

.dataLots{
    line-height: 8px;

    font-size: clamp(6px, 2.8vw, 8px);
    color: rgba(96, 96, 96, 1);

}
.countLotsNumber{
    /* display: flex;
    flex-direction: row; */
    flex-wrap: nowrap;
    font-size: clamp(8px, 3.2vw, 10px);
    /* line-height: 12px; */

}

.countLotsText{
    font-size: clamp(18px, 4.0vw, 22px);
    font-weight: 401;   
    /* line-height: 12px; */
    
}