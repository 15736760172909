.container{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
    padding: 10px 10px 10px;
    
}

.title{
    font-family :'Rubik', sans-serif;
    font-weight : 700;
    font-size: clamp(15px, 5vw, 20px);
    line-height: 1.2em;
    text-align: center;
    /* padding: 10px 10px 10px; */
    color :rgba(0,0,0,1);
    background-color: white;
    margin-bottom: 10px;

}

.itemContainer{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    background-color: white;
    gap : 10px;
}

.itemInfo{
    display: flex;
    flex-direction: column;
    gap : 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;


}

.itemImage {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
}

.itemImage img {
    width: 100%; /* Make image width equal to container width */
    height: 100%; /* Make image height equal to container height */
    object-fit: contain;
    border-radius: 5px;
}

.priceContainer{
    display: flex;
    flex-direction: column;
    
    align-items: flex-start ;
    /* padding-left: 10px; */
    gap : 5px;

}

.itemTotal{
    font-weight: 700;
    font-size: clamp(100%, 2.9vw, 12px);
}

.itemPrice{
    font-weight: 400;
    font-size: clamp(100%, 2.9vw, 12px);
    color: black;
}

.itemDescription{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: clamp(8px, 2.7vw, 10px);
    color: rgba(96, 96, 96, 1);
}

.itemName{
    font-weight: 500;
    font-size: clamp(10px, 3.1vw, 13px);
    color: #000;
}

.itemStatusContainer{
    display: flex;
    flex-direction: row;
    
    align-self: flex-end;
}

.deliveryContainer{
    display: flex;
    background: rgba(237, 237, 237, 1);
    border-radius: 5px;
    padding: 5px 5px 5px;
    align-items: center;
    text-align: center;
    gap : 5px;
    padding-right: 10px;
    height: 30px;
}

.estimateContainer{
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    gap: 10px;

}

.estimateTitle{
    display: flex;
    text-align: flex-start;
    font-family: 'Rubic', sans-serif;
    font-weight: 600;
    font-size: clamp(10px, 2.8vw, 12px);

}

.estimateButtonContainer{
    display: flex;
    flex-direction: row;
    gap : 10px;
    padding-top: 5px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-around;

}


.estimateButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 25px;
    background-color: rgba(237, 237, 237, 1);
    border-radius: 10px;
    border: 1px solid rgba(181, 181, 181, 1);
    font-family: 'Rubic', sans-serif;
    

    
}

.active{
    border: 2px solid var(--primary-color); 
}

.button{
    
        width : 100%;
        height: 40px;
        border-radius: 10px;
        /* background: rgba(79, 231, 112, 1); */
        background: rgba(79, 140, 231, 1);
        color: rgba(255, 255, 255, 1);
        font-family: "Rubik", sans-serif;
        font-weight: 600;
        font-size: clamp(10px, 2.8vw, 12px); 
        border: none;
        
    
}