.ProductCard {

  
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.CostBox {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
}

.InfoAll {
    color: black;
    padding: 0 15px;
}

.Name {
    display: flex;
    justify-self: flex-start;
    letter-spacing: 1px;
    font-style: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: clamp(16px, 3.0vw, 20px);
    line-height: 20px;
    margin-top: -15px;
}

.Describtion {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 0;
}

.Cost {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    padding: 10px 0;
}

.Footer {
    padding: 5px 10px 15px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    z-index: 10;
    margin-bottom: 60px;
}

.footerContent {
    margin: 0 auto;
    max-width: 428px;
}


.Share {
    display: flex;
    justify-content: space-around;
}

.starCount {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin-left: 10px;
}

.buyersChoice {
    background-color: #ededed;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    padding: 0 24px;
    border-radius: 20px;
    border: none;
}

.star {
    width: 20px;
}

.row {
    margin-top: 10px;   
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 10px;
    /* flex-wrap: nowrap; */
    /*padding: 0 20px;*/
}


.shop {
    display: flex;
    justify-items: center;
    flex-direction: row;
}

 .shopContainer {
    display: flex;
    margin-top: auto;
} 

.price {
    font-weight: 600;
    font-size: 22px;
    color: #000;
}

.shopName {
    width: 100%;
    align-self: center;
    margin-left: 15px;
}

.approveLabel {
    width: 10px;
    margin-left: 5px;
}

.shopLogo {
    width: 40px;
}

.showVideosButton, .showVideosButtonDisabled {
    background-color: var(--primary-color);
    color: white;
    display: flex;
    /* justify-content: space-around; */
    gap: 10px;
    /* justify-content: flex-end; */
    align-items: center;
    font-weight: 400;
    font-size: clamp(8px, 2.7vw, 11px);
    line-height: 14px;
    width: 70%;    
    border-radius: 10px;
    padding: 8px 0;
    padding-left: 5px;
    flex-wrap: nowrap;
}
.showVideosButtonDisabled{
    background-color: #B5B5B5;

}

.showVideosButton img {
    width: 5vw;
}

.showVideosButton img {
    width: 5vw;
}


.separator {
    width: 100%;
    height: 15px;
    background: rgba(96, 96, 96, 0.1);
}

.shopSeparator {
    width: 100%;
    height: 15px;
    background: rgba(96, 96, 96, 0.1);
    margin-top: 15px;
}

.contactStore {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: left;
}

.horisontalGoods {
    width: 100vw;
}

.shareContainer {
    /* position: absolute; */
    width: 20px;
    height: 20px;
    right: 20px;
    top: 25px;
    z-index: 4;
    /* background: red; */
}

/* .likeProductContainer { */
    /* position: absolute; */
/*  */
    /* width: 20px; */
    /* height: 20px; */
    /* left: 5px; */
    /* top: 30px; */
    /* z-index: 4; */
/* } */


/* @media screen and (max-width: 380px) {
    .showVideosButton {
        font-size: 10px;
    }

    .showVideosButton img {
        display: none;
    }

    .buyersChoice {
        font-size: 10px;
        padding: 0 5vw;
    }
} */


@media screen and (max-width: 360px) {
    .InfoAll {
        padding: 0 8px;
    }
}

@media screen and (max-width: 340px) {
    .InfoAll {
        padding: 0 4px;
    }
}


