.wrapper {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.titleContainer{
    width: 100%;
    height: 160px;
    display: flex;
    
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding-top: 110px ;
    padding-bottom : 170px;
}


.title1 {
    color : rgba(23, 51, 93, 1);
    width: 80%;
    height: 100%;
    text-align: center;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    padding: 0;

    vertical-align: bottom;
}

.title2 {
    color : rgba(23, 51, 93, 1);
    width: 40%;
    height: 80px;
    font-family: 'Rubik', sans-serif;
    font-size: 1.4rem;
    text-align: left;
    font-weight: 50;
    padding-right: 20px ;
    line-height: 1.2;
    padding-top: 20px;
}

.сontainer{
    margin-top: 90px;    
}

.title3 {
    font-size: 1.5rem;
    padding: 0;
    margin-bottom: 15px;
}

.separator {
    background: #EDEDED;
    width: 100%;
    height: 15px;
    /*margin: 10px 0;*/
}