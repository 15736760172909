.modal {
	height    : auto;
	width     : 100vw;
	background: #FFF;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
	position  : fixed;
	bottom    : 62px;
	left      : 0;
	z-index   : 10;
	transform : translateY(100%);
	transition: transform 1s ease-out;
}

.active {
	transform : translateY(0);
	transition: transform 1s ease-out;

}

.closeLineContainer {
	padding: 7px 0 11px;
}

.closeLine {
	width        : 30px;
	height       : 3px;
	border-radius: 2px;
	background   : #606060;
	margin       : 0 auto;
}

.modalContent {
	padding       : 10px 10px 50px;
	border-radius : 10px;
	background    : #FFF;
	display       : flex;
	flex-direction: column;
	row-gap       : 20px;
	font-weight   : 500;
	font-size     : 14px;
	line-height   : 20px;
}

.title {
	font-weight   : 600;
	font-size     : 14px;
	line-height   : 18px;
	letter-spacing: 0.05em;
	text-align    : left;
}

.row {
	display        : flex;
	flex-direction : row;
	justify-content: flex-start;
	align-items    : center;
	column-gap     : 9px;
}

.circle {
	display      : inline-block;
	width        : 20px;
	height       : 20px;
	border-radius: 50%;
	border       : 2px solid #606060;
}

.activeCircle {
	border: 3px solid transparent;
		background: #f5f7fa;
	border-color: #4F8CE7;
	/* background: linear-gradient(#FFF, #fff) padding-box, */
		/* linear-gradient(90deg, #ef6666 0%, #0628f1 49.48%, #ef72f2 100%) border-box; */
}

.complaints {
	display    : inline-block;
	font-weight: 500;
	font-size  : 12px;
	line-height: 12px;
	text-align : left;
}

.other {
	border-radius   : 10px;
	border          : none;
	background-color: #EDEDED;
	resize          : none;
	padding         : 14px;
	font-weight     : 500;
	font-size       : 12px;
	line-height     : 14px;
	color           : #606060;
}

.other::placeholder {
	font-weight   : 500;
	font-size     : 12px;
	line-height   : 12px;
	letter-spacing: 0.05em;
}

.other:focus {
	outline     : none !important;
	border-color: #606060;
	box-shadow  : 0 0 5px #606060;
}

.send {
	width         : 100%;
	/* background    : linear-gradient(90.62deg, rgba(240, 6, 6, 0.6) 0%, rgba(6, 40, 241, 0.6) 49.48%, rgba(239, 114, 242, 0.6) 100%); */
	/* box-shadow    : 0px 0px 10px rgba(0, 0, 0, 0.25); */
	/* border-radius : 20px; */
	background: #4F8CE7;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	
	border        : none;
	font-style    : normal;
	font-weight   : 700;
	font-size     : 16px;
	line-height   : 12px;
	letter-spacing: 0.075em;
	padding       : 14px;
	color         : white;
}