.Slide {
    width: auto !important;
    background-color: #ededed;
    padding: 8px 12px;
    border-radius: 20px;
    text-transform: uppercase;
}

.Sort {
    display: flex;
    justify-content: space-around;
    height: 23px;
    padding-bottom: 5px;
}

.Sort h5 {
    margin: 0;
    padding: 0;
}

.container {
    /* margin-top: 30px; */
    padding: 10px;
}

.sortList {
    font-weight: 600;
    font-size: clamp(9px, 11px, 13px);
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-top: 1em;
    padding-bottom: 1.2em;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 41px;
}

.sortListPrice {
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #606060;
    list-style: none;
    padding-bottom: 1.2em;
    text-align: left;
}

.sortList:first-child {
    border-top: solid 1px #606060;
}


.filterChoice {
    display: flex;
    align-items: center;
    justify-content: center ;
    width: 20px;
    height: 20px;
    border: 2px solid #606060;
    border-radius: 50%;
    margin-right: 6px;
}

.active {
    border: 4px solid transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--primary-color) 0%, var(--primary-color) 100%) border-box;
    /*background: var(--primary-color);*/
}

.sortText {
    display: flex;
    justify-self: center;
    align-self: center;
}

.sortTextPrice {
    display: inline-flex;
    justify-self: center;
    align-self: center;
    padding-bottom: 2rem;
}

.line {
    width: 30px;
    height: 2px;
    background-color: #606060;
    margin: 0 15px;
}

.filterContainer ul{
    padding: 0;
    font-family: 'Rubik', sans-serif;
    margin-bottom:20px;
}


.align {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filterForm ul {
    padding: 0;
}

.filterCost {
    min-width: 93px;
    width: 20vw;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    border: solid 1px #ededed;
    color: #606060;
    background: #ededed;
    border-radius: 10px;
    padding: 9px;
}

.filterCost:focus {
    outline: none;
    border: solid 1px #606060;
}

.link {
    width: 13px;
    height: 11px;
}

.applyButton {
    width: 100%;
    /*background: linear-gradient(*/
    /*        90.62deg,*/
    /*        rgba(240, 6, 6, 0.6) 0%,*/
    /*        rgba(6, 40, 241, 0.6) 49.48%,*/
    /*        rgba(239, 114, 242, 0.6) 100%*/
    /*);*/
    height: 35px;
    background: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.075em;
    /* padding: 14px; */
    /* margin-top: 88px; */
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
}

.count {
    color: #A3A3A3;
}

.itemCardFormatContainer {
    margin-bottom: 20px;
}

.itemCardFormatDisplayContainer {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    padding-left: 60px;
    padding-right: 60px;
    /* border: 2px solid #606060; */
}

.itemCardFormatDisplayContainer input[type="radio"] {
    display: none;
}

.cardFormatInput {
    padding: 7px 26px 6px;
    background-color: var(--light-background-color);
    border-radius: 10px;
    border: 1px solid var(--dark-background-color);
}

.cardFormatInput path {
    fill: var(--dark-background-color);
}

.activeCardFormatInput {
    border: 2px solid var(--primary-color);
}

.activeCardFormatInput path {
    fill: var(--primary-color);
}

.filterTitle{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: -15px;
    
}