.wrapper {
    flex-grow: 1;
    position: relative;
    /*border: 1px solid red;*/
    z-index: 19;
    /*margin-top: 2vh;*/
}

.wrapper img {
    position: absolute;
    right: 25px;
    top: 35px;
}

.registeredInput {
    width: 100%;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    background-color: #EDEDED;
    border: none;
    border-radius: 10px;
    padding: 10px 5px;
    margin-top: 5px;
    cursor: pointer;
}

.registeredInput:focus-visible {
    outline: none;
}

.registeredInput::placeholder {
    color: #606060;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.label {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 0 14px;
}