* {
    box-sizing: border-box;
    margin: 0;
    letter-spacing: 0.05em;
}

canvas {
    max-width: 100vw !important;
    max-height: 100vh !important;
}

button,
input,
select,
textarea {
    font: inherit;
}

body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    background-color: #ffffff;
    --primary-color: #4F8CE7;
    --dark-background-color: #B5B5B5;
    --light-background-color: #EDEDED;
    --shared-color: #E74F4F;
    --grey-text-color: #606060;
    /* --main-font: 'PT Sans', sans-serif; */
    --main-font: 'Rubic', sans-serif;
    --secondary-font: 'Rubik', sans-serif;
    font-family: var(--main-font)
}


.SubCategorySwiperSlide {
    width: auto !important;
    background-color: #eaeaea;
    padding: 8px 12px;
    border-radius: 20px;
    text-transform: uppercase;
    border: 2px solid #eaeaea;
}

.SubCategorySwiperSlide-active {
    background: linear-gradient(#eaeaea, #eaeaea) padding-box,
    linear-gradient(var(--primary-color) 0%, var(--primary-color) 100%) border-box;
    border: 2px solid transparent;
}

.visible {
    display: block;
    width: 100%;
}

.invisible {
    display: none;
}

.amountsCount {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    background-color: #ef2222;
    position: absolute;
    top: -5px;
    right: -7.5px;
}

a {
    text-decoration: none;
    color: inherit;
}

