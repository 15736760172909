
/* for items */

.itemContainer{
    display: flex;
    gap:10px;
}



.brandList {
    display: flex;
    flex-direction: column;
    max-height: 60vh; /* You can adjust this value */
    overflow-y: auto;
    background: rgba(96, 96, 96, 0.1);
    gap : 10px;
    /* max-height: calc(100% - 50px);  */
  }





.contentContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    gap: 15px;  
    max-height: 70vh;
    overflow-y: auto;

}


.search{
    width: 97%;
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    background: rgba(237, 237, 237, 1);
    border: none;
    padding-left: 10px;
    font-size: clamp(8px, 3.4vw, 14px);
}



.form{
    display: flex;
    flex-direction: column;
    gap : 10px;
    background: rgba(237, 237, 237, 1);

}

.wraperItem{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}



.itemContainer{
    width: 100%;
    height: 100%;
    /* height: 60p; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background: white; */
    
    /* justify-content: space-between; */
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.itemImage{
    margin-right: 15px; 
    }

.itemImage img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        /* margin-right: 15px; */
    }
    


.itemInfo{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        /* padding: 10px; */
        /* border: 1px solid rgba(0, 0, 0, 0.1); */
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-align: left;
        font-family: 'Rubik', sans-serif;
    }

.itemArticle{
    font-size: clamp(6px, 2.8vw, 8px);
    
}

.itemName{
    font-size: clamp(8px, 3.4vw, 12px);
}


.itemPurposeCount{
    font-size: clamp(5px, 2.6vw, 7px);
}


.link{
    font-weight: 501;
    color: var(--primary-color);
    font-size: clamp(8px, 3.0vw, 12px); 
}

.countButton{
    display: flex;
    flex-direction: row;
    height: 25px;
    justify-content: flex-end;
    gap: 5px;
    align-self: flex-end;
    align-items: center;
}

.doButton {
    display: flex;
    flex-direction: row;
    /* height: 25px; */
    /* width: 30%; */
    /* justify-content: space-around; */
    align-items: center;  
    border-radius: 50px;
    border: none;
    background: rgba(217, 217, 217, 1);
    color: rgba(181, 181, 181, 1);
    height: 15px;
    width: 15px;
}
  
.buttonText {
    border: none;
    background: none;   
    align-items: flex-start;
    justify-content: flex-start;
    outline: none; 
    /* width: 2em;  */
    text-align: center;
    flex: 1;
  }
  
  .buttonText:focus {
    border-bottom: 1px solid #000;
  }
  
  /* Hide arrows */
  .buttonText[type='number'] {
    -moz-appearance: textfield;
  }
  
  .buttonText[type='number']::-webkit-inner-spin-button,
  .buttonText[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.buttonFixed{
    position: fixed;
    bottom: 70px;
    left: 2.5%;  /* Add this property */
    width: 95%;
    height: 35px;
    background: rgba(79, 140, 231, 1);
    border-radius: 10px;
    border: none;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    z-index: 999;
}
.buttonFixed:disabled {
    background: rgba(189, 189, 189, 0.6);
    cursor: default;
    }
    


/* .line{
    height:2vb; 
    background: rgba(237, 237, 237, 1);
    margin-top: 5px;
    width: 100%;
} */






/* for variants */    



.wraperVariat{
    margin-bottom: 10px;
    padding-left: 10px;
    /* padding-right: 10px; */
    gap: 10px;
    /* display: flex; */
    /* flex-direction: row; */
   
}



.infoVariant{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 10px; */
}




.row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* margin-top: 5px; */
        margin-bottom: 5px;

    }
    
.variantsInfo{
        display: flex;
        flex-direction: column;
        /* gap : 10px; */
        color: rgba(96, 96, 96, 1);

        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
        font-size: clamp(6px, 3.0vw, 10px);
        letter-spacing: 0.05em;
        text-align: left;

    }
    
    
.variantText{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: flex-start;
        gap:10px;
        width: 100%;
    }

.labelInfo{
        font-size: clamp(6px, 2.9vw, 10px);
    }
.textInfo{
        font-size: clamp(6px, 2.9vw, 10px);
        font-weight: 500;
    }



.character{
        background: rgba(237, 237, 237, 1); 
        width: 100%;
        height: 40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        line-height: 12px;  
        font-family: 'Rubik', sans-serif;
    }
    
    .character{
        flex-basis: calc(50% - 10px); /* Assuming a gap of 10px */
        box-sizing: border-box;
    }
    
    .character:last-child:nth-child(odd) {
        flex-basis: 100%;
      }
    
      
    
.characterRow{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
    }


.inputText {
    border: none;
    width: 15px;
    background: none;
    align-items: flex-start;
    justify-content: flex-start;
    outline: none;
    text-align: center;
    flex: 1;
   
}

.inputText[type='number'] {
    -moz-appearance: textfield;
  }

  .inputText[type='number']::-webkit-inner-spin-button,
  .inputText[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }