.wrapper {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

.title1 {
    text-align: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    padding: 0;

    vertical-align: bottom;
}

.title2 {
    margin-top: 5%;
    padding: 0;
    font-size: 30px;
}

.text {

}

.item {
    font-weight: 900;
    font-size: 36px;
    vertical-align: bottom;
    margin-left: 10px;
}